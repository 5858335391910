import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {
  Typography,
  FormControl,
  MenuItem,
  Select,
  Button,
  Container,
  Grid,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {styled} from '@mui/styles';
import {DropdownStyle} from '@app/components/widgets/TextFieldStyle';
import {
  expenseStateActions,
  fetchDownloadCreator,
  fetchExpenseParameterCreator,
  fileUploadCreator,
} from './redux/slice';
import {expenseStateSelector} from './redux/selectors';
import {ErrorGrid} from '../../components/expense/errorGrid';
import {appSelector} from '@app/store/selectors';
import {monthMock} from '@app/components/widgets/placeholder/multiselect-placeholder';
import {generateLastTenYears} from '../../components/approve-expenses/utils';
import {useStyles} from '@app/screens/Styles/style';

const Input = styled('input')({
  display: 'none',
});
export const util: any = {
  handleChangeParameter: null,
  handleChangeYear: null,
  onchanges: null,
  handleChangeMonth: null,
};
export const changeHandler = (
  event: any,
  dispatch: any,
  parameter: any,
  year: any,
  setErrorMessage: any,
  month: any,
) => {
  const file = event.target.files[0];
  event.target.value = '';
  const formData = new FormData();
  formData.append('AdjustmentParameterId', parameter);
  formData.append('AdjustmentMonth', month);
  formData.append('AdjustmentYear', year);
  formData.append('Blob', file);
  if (file !== undefined) {
    dispatch(fileUploadCreator(formData));
    setErrorMessage(true);
  }
};

export const downloadBase64File = (
  setErrorMessage: any,
  parameter: any,
  base64: any,
  parameterArr: any,
) => {
  setErrorMessage(false);
  if (parameter !== '') {
    const linkSource = `data:text/csv;base64,${base64}`;
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    let filename = parameterArr?.filter(
      (element: any) => element.expenseAdjustmentParameterId === parameter,
    );
    downloadLink.href = linkSource;
    downloadLink.target = '_self';
    downloadLink.download = filename[0]?.expenseAdjustmentParameterName;
    downloadLink.click();
  }
};
export const Expense = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [parameter, setParameter] = useState('');
  const [year, setYear] = useState('');
  const [goButton, setGoButton] = useState(true);
  const [month, setMonth] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const response = useSelector(expenseStateSelector.getUploadResponse());
  const {isWriteAccess = false} = useSelector(appSelector.getUserInfo());
  const base64 = useSelector(expenseStateSelector.getDownload());
  const parameterArr = useSelector(expenseStateSelector.getParameter());
  const handleChangeParameter = useCallback(
    (event: any) => {
      setParameter(event.target.value);
      setErrorMessage(false);
    },
    [parameter, errorMessage],
  );
  const handleChangeYear = useCallback(
    (event: any) => {
      setYear(event.target.value);
      setErrorMessage(false);
    },
    [year, errorMessage],
  );
  const handleChangeMonth = useCallback(
    (event: any) => {
      setMonth(event.target.value);
      setErrorMessage(false);
    },
    [month, errorMessage],
  );
  const lastTenYears: number[] = generateLastTenYears();
  const onchanges = useCallback(
    (e: any) => {
      changeHandler(e, dispatch, parameter, year, setErrorMessage, month);
    },
    [parameter, year, dispatch, setErrorMessage,month],
  );
  const downloadBase = useCallback(() => {
    downloadBase64File(setErrorMessage, parameter, base64, parameterArr);
  }, [setErrorMessage, parameter, base64, parameterArr]);
  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(navbarComponentName.Expense),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeadingEmpty}>Expense</div>,
      ),
    );
    dispatch(fetchExpenseParameterCreator());
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(expenseStateActions.setUploadResponse([]));
    };
  }, [classes.navbarComponentHeadingEmpty, dispatch]);

  useEffect(() => {
    if (parameter !== '' && month !== '' && year !== '') setGoButton(false);
    else setGoButton(true);
  }, [year, month, parameter]);

  useEffect(() => {
    if (parameter !== '') {
      dispatch(fetchDownloadCreator(parameter));
    }
  }, [parameter, dispatch]);
  util.handleChangeParameter = handleChangeParameter;
  util.handleChangeYear = handleChangeYear;
  util.onchanges = onchanges;
  util.handleChangeMonth = handleChangeMonth;
  return (
    <>
      <div className={classes.toolbarexpense}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Typography>
              <label className={classes.labelSizing}>PARAMETER</label>
            </Typography>
            <FormControl sx={{marginTop: 1}} fullWidth>
              <Select
                inputProps={{
                  'data-testid': 'dropdown-handleChangeParameter',
                }}
                size="small"
                fullWidth
                disabled={!isWriteAccess}
                value={parameter}
                onChange={handleChangeParameter}
                sx={DropdownStyle.smroot}>
                {parameterArr.map((event: any) => (
                  <MenuItem
                    key={event.expenseAdjustmentParameterId}
                    value={event.expenseAdjustmentParameterId}>
                    {event.expenseAdjustmentParameterName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              <label className={classes.labelSizing}>MONTH</label>
            </Typography>
            <FormControl sx={{marginTop: 1}} fullWidth>
              <Select
                inputProps={{
                  'aria-label': 'Without label',
                  'data-testid': 'dropdown-handleChangeMonth',
                }}
                size="small"
                fullWidth
                disabled={!isWriteAccess}
                value={month}
                sx={DropdownStyle.smroot}
                onChange={handleChangeMonth}>
                {monthMock().map((event1: any) => (
                  <MenuItem key={event1.key} value={event1.key}>
                    {event1.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Typography>
              <label className={classes.labelSizing}>YEAR</label>
            </Typography>

            <FormControl sx={{marginTop: 1}} fullWidth>
              <Select
                size="small"
                fullWidth
                disabled={!isWriteAccess}
                value={year}
                inputProps={{
                  'aria-label': 'Without label',
                  'data-testid': 'dropdown-handleChangeYear',
                }}
                sx={DropdownStyle.smroot}
                onChange={handleChangeYear}>
                {lastTenYears.map((yearOption: any) => (
                  <MenuItem key={yearOption} value={yearOption}>
                    {yearOption}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <div>
              <label htmlFor="contained-button-file">
                <Input
                  data-testid="goButton-input"
                  accept=".csv"
                  id="contained-button-file"
                  type="file"
                  onChange={onchanges}
                  disabled={goButton}
                />
                <Button
                  data-testid="goButton"
                  variant="contained"
                  component="span"
                  className={classes.expensego}
                  disabled={goButton}
                  size="large">
                  Go
                </Button>
              </label>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={classes.download}>
        <span data-testid="download" onClick={downloadBase}>
          Download Template
        </span>
      </div>

      <div className={classes.errorcss}>
        <Container maxWidth={false}>
          {errorMessage &&
            response?.isAllSucceeded &&
            'Adjustment made successfully'}
          {errorMessage &&
            response?.errorMessage &&
            '' + response?.errorMessage + ''}
          {errorMessage &&
            response?.invalidItems?.length > 0 &&
            !response?.isAllSucceeded &&
            'Out of all the records ' +
              response?.invalidItems?.length +
              ' records got failed'}
          {errorMessage && response?.invalidItems?.length > 0 && (
            <ErrorGrid ErrorData={response?.invalidItems} />
          )}
        </Container>
      </div>
    </>
  );
};
