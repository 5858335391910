import { createSelector } from "@reduxjs/toolkit";

const partyMasters = (state: any) =>
  state.digitalTwinPartyMasterState.parties;


const getPartyMasters = createSelector(
  [partyMasters],
  (_partyMasters) => _partyMasters
);

const downloadExcelURL = (state: any) =>
  state.digitalTwinPartyMasterState.downloadExcelURL;


const getDownloadExcelURL = createSelector(
  [downloadExcelURL],
  (_downloadExcelURL) => _downloadExcelURL
);

export const digitalTwinPartyMasterSelector = {
  getPartyMasters: () => getPartyMasters,
  getDownloadExcelURL: () => getDownloadExcelURL,
};
