import Typography from '@mui/material/Typography';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {Button} from '@mui/material';
import {routeSelector} from '@app/router/redux';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER} from '@app/utils/analytics/constants';
import {componentManagementStateActions} from '@app/screens/component-management/components/redux';
import {SearchBarExpenseType} from './searchbar';
import { appSelector } from '@app/store/selectors';
import { navbarCss } from '@app/components/widgets/navbar/navbarCss';
import { useCallback } from 'react';

export const ComponentManagementBar = () => {
  const dispatch = useDispatch();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );

  const { isWriteAccess = false} = useSelector(
    appSelector.getUserInfo(),
    shallowEqual
  );

  const manageAddNewPopup = useCallback(()=> {
    trackEvent(EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER, {});
    dispatch(
      componentManagementStateActions.setaddExpenseTypePopupVisible(true),
    );
  },[]);
  
  const TriggerEvent = useCallback(() =>{
    return (
      <button
        data-testid="add-component-new"
        className={"hiddenButton"}
        onClick={manageAddNewPopup}
      />
    );
  },[]
  );
  return (
    <div style={navbarCss.containercss}>
      <div style={navbarCss.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#322b7c'}
          fontWeight={'800'}
        >
          {navbarComponentHeading}
        </Typography>
        <div style={navbarCss.flexSpaceBetween}>
          <Button
            variant="contained"
            disabled={!isWriteAccess}
            sx={navbarCss.button}
            onClick={manageAddNewPopup}
          >
            Add New
          </Button>
        </div>
      </div>
      <TriggerEvent/>
      <SearchBarExpenseType />
    </div>
  );
};
