import {useEffect} from 'react';
import {routeSelector} from '@app/router/redux';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { filterStateActions } from '@app/components/right-sidebar/redux';
export const MasterDataManagement = () => {
  const appRoutes = useSelector(routeSelector.getAppRoutes());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    let selectedRoute: any = {};
    appRoutes.map((route: any) => {
      if (route.webPath === '/master-data-management') {
        selectedRoute = route;
      }
      return null;
    });
    if (
      selectedRoute?.firstLevel?.length > 0
    ) {
      const firstRoute: any = selectedRoute.firstLevel[0];
      navigate(firstRoute.webPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRoutes]);
  useEffect(() => {
    return () => {
      dispatch(filterStateActions.setFilterState({}));
    }
  }, [dispatch]);
  return <div></div>;
};
