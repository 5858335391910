import {
  Box,
  Modal,
  IconButton,
  CircularProgress,
  Backdrop,
  Typography,
  Stack,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import { FC } from "react";

interface PopupProps {
  isLoading?: boolean;
  cancelPopup?: () => void;
  open: boolean;
  onClose?: () => void;
  error?: null | string;
  title?: string;
}

const PopupDefaultProps: PopupProps = {
  open: false,
  isLoading: false,
  cancelPopup: () => {
    //to cancel the Popup
  },
  onClose: () => {
    // to close the Popup
  },
};

const SecondaryPopup: FC<PopupProps> = ({
  isLoading,
  cancelPopup,
  open,
  onClose,
  children,
  error,
  title,
}) => {
  return (
    <Modal open={open} keepMounted onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 533,
          p: 3,
          backgroundColor: "#ffffff",
        }}
      >
        {isLoading ? (
          <Backdrop sx={{ zIndex: 9999 }} open>
            <CircularProgress sx={{ color: "primary.main" }} disableShrink />
          </Backdrop>
        ) : null}
        <Stack
          justifyContent={"space-between"}
          alignItems="center"
          direction={"row"}
          sx={{ pb: 2.5 }}
        >
          {title ? (
            <Typography variant="popupHeading">{title}</Typography>
          ) : null}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={cancelPopup}>
              <Close sx={{ color: "primary.dark" }} />
            </IconButton>
          </Box>
        </Stack>
        {error ? (
          <Box sx={{ left: 30, position: "absolute", top: 5 }}>
            <Typography variant="error" color={"red"}>{error}</Typography>
          </Box>
        ) : null}
        {children}
      </Box>
    </Modal>
  );
};

SecondaryPopup.defaultProps = PopupDefaultProps;

export default SecondaryPopup;
