import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDispatch, useSelector} from 'react-redux';
import {
  resetDefaultFilterCreator,
  setDefaultFilterCreator,
} from '../redux/filterHandler';
import {filterSelector, filterStateActions} from '../redux';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

interface IProps {
  type: string | undefined;
  open: boolean;
  onClose: () => void;
}
const defaultValues: any = {
  set: {
    title: 'Set default filters',
    content:
      'This action will set current filters as default. Are you sure you want to continue?',
    successMessage: 'Default filters saved successfully',
    errorMessage: 'some error occurred',
  },
  reset: {
    title: 'Reset default filters',
    content:
      'This action will Reset default filters. Are you sure you want to continue?',
    successMessage: 'Default filters reset successfully',
    errorMessage: 'some error occurred',
  },
};
const DefaultFilterActionDialog = (props: IProps) => {
  const {open, type, onClose} = props;
  const dispatch = useDispatch();
  const setDefaultActionResponse = useSelector(
    filterSelector.getDefaultFilterResponse(),
  );
  React.useEffect(() => {
    if (setDefaultActionResponse !== null) {
      const {actionType, data} = setDefaultActionResponse;
      if (data) {
        ToasterService.showToaster(
          actionType && defaultValues[actionType]?.successMessage,
          ToastType.SUCCESS_ACTION,
        );
      } else {
        ToasterService.showToaster(
          actionType && defaultValues[actionType]?.errorMessage,
          ToastType.ERROR,
        );
      }
      dispatch(filterStateActions.setDefaultFilterResponse(null));
    }
  }, [setDefaultActionResponse]);
  
  const onActionButtonClick = () => {
    onClose();
    if (type === 'set') {
      dispatch(setDefaultFilterCreator(type));
    } else {
      dispatch(resetDefaultFilterCreator(type));
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {type && defaultValues[type]?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {type && defaultValues[type]?.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button data-testid="button-cancel" onClick={onClose}>Cancel</Button>
          <Button data-testid="button-ok" onClick={onActionButtonClick} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default DefaultFilterActionDialog;
