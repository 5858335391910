import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import { routeSelector } from "@app/router/redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  flexSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
  },
});

export const StatusManagerNavBar = () => {
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading()
  );
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={"#322b7c"}
          fontWeight={"800"}
        >
          {navbarComponentHeading}
        </Typography>
      </div>
    </div>
  );
};
