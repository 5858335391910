import { createAction, createSlice } from "@reduxjs/toolkit"

export const statusManagerInitialState={
    statusManagerAutoSuggestStaffDetails:[],
    statusManagerAutoSuggestLoading:false,
    statusManagerAction:[]
}

export const fetchStatusManagerAutoSuggestStaffCreator = createAction<any>(
    "STATUS_MANAGER_HANDLER/FETCH_AUTO_SUGGEST_STAFF"
  );
  export const fetchStatusManagerAutoSuggestStaffCreatorTypeName =
    fetchStatusManagerAutoSuggestStaffCreator({}).type;

    export const fetchGetExpenseStatusActionCreator = createAction<any>(
      "STATUS_MANAGER_HANDLER/FETCH_EXPENSE_STATUS_ACTION"
    );
    export const fetchGetExpenseStatusActionCreatorTypeName =
    fetchGetExpenseStatusActionCreator({}).type;

    export const saveExpenseStatusActionCreator = createAction<any>(
      "STATUS_MANAGER_HANDLER/SAVE_EXPENSE_STATUS_ACTION"
    );
    export const saveExpenseStatusActionCreatorTypeName =
    saveExpenseStatusActionCreator({}).type;

const statusManagerStateHandler = createSlice({
    name: "STATUS_MANAGER_HANDLER",
    initialState: statusManagerInitialState,
    reducers: {
        setStatusManagerAutoSuggestStaffDetail:(prevState, action) => {
            const { payload } = action;
            return {
              ...prevState,
              statusManagerAutoSuggestStaffDetails: payload,
            };
          },
          setStatusManagerAutoSuggestLoading:(prevState, action) => {
            const { payload } = action;
            return {
              ...prevState,
              statusManagerAutoSuggestLoading: payload,
            };
          },
          setStatusManagerAction:(prevState, action) => {
            const { payload } = action;
            return {
              ...prevState,
              statusManagerAction: payload,
            };
          },
    }
})
export const statusManagerStateActions = statusManagerStateHandler.actions;
export const statusManagerStateReducer = statusManagerStateHandler.reducer;