import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { routeSelector } from '@app/router/redux';
import { Label } from '@app/components/elements/Label';
import { useStyles } from './styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SearchBar } from '@app/components/widgets/searchBar';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { POSITION_TYPE, PARTY_TYPE } from '@app/common/constants'
import { positionManagementStateActions, positionManagementSelector, fetchPartyDetailBySpidCreator, fetchCloningTimePeriodCreator } from '@app/screens/module-specific-utilities/pages/position-management/redux'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton';
import {positionManagementInitialState} from '@app/screens/module-specific-utilities/pages/position-management/redux/slice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const seachFilterOptions = [
    {
        name:'Position ID',
        value:1,
        key:'staffPositionId'
    },
    {
        name:'Employee ID',
        value:2,
        key:'employeeId'
    },
    {
        name:'Employee Name',
        value:3,
        key:'employeeName'
    },
]

export const PositionManagementNavbar = () => {
    const dispatch = useDispatch();
    const navbarComponentHeading = useSelector(
        routeSelector.getNavbarComponentHeading(),
    );
    const [searchToError, setSearchToError] = useState(false);
    const [searchFromError, setSearchFromError] = useState(false);
    const [toErrorMsg, setToErrorMsg] = useState('');
    const [fromErrorMsg, setFromErrorMsg] = useState('')
    const [period, setPeriod] = useState(0);
    const [partiesSelected, setPartiesSelected] = useState('')
    const [positionType, setPositionType] = useState(POSITION_TYPE.transfer);
    const [searchFrom, setSearchFrom] = useState('');
    const [searchTo, setSearchTo] = useState('');
    const [fromSearchOptionSelected,setFromSearchOptionSelected] = useState(1)
    const [toSearchOptionSelected,setToSearchOptionSelected] = useState(1)
    const [showFromSuggestion,setShowFromSuggestion] = useState(false);
    const [showToSuggestion,setShowToSuggestion] = useState(false);
    const [fromSuggestionList,setFromSuggestionlist] = useState([]);
    const [toSuggestionList,setToSuggestionList]= useState([])
    const partyDetails = useSelector(positionManagementSelector.getPartyDetails())
    const storedPositionType = useSelector(positionManagementSelector.getPositionType())
    const partiesSelectedForTransfer = useSelector(positionManagementSelector.getPartiesSelectedForTransfer())
    const cloningTimePeriod = useSelector(positionManagementSelector.getCloningTimePeriod())
    const transferPositionResponse = useSelector(positionManagementSelector.getTransferPositionResponse())

    const classes = useStyles();

    useEffect(() => {
        dispatch(fetchCloningTimePeriodCreator())
    }, [])

    useEffect(() => {
        if(positionType !== storedPositionType) {
            resetFields();
            dispatch(positionManagementStateActions.setPositionType(positionType));
        }
    }, [storedPositionType])

    useEffect(() => {
        if(transferPositionResponse){
            setSearchFrom('')
            setSearchTo('')
        }
    },[transferPositionResponse])

    useEffect(() => {
        for (let key in partyDetails) {
            if (key !== 'cloningPeriod' && (Object.keys(partyDetails[key]?.details)?.length !== 0 || partyDetails[key]?.details?.length === 0 )) {            
                const details = partyDetails[key]?.details;
                if (key === 'from') {
                    if(details?.length > 1){
                        setShowFromSuggestion(true);
                        setFromSuggestionlist(details);
                        return;
                    }
                    if (details?.staffPositionId && details?.staffPositionId !== partyDetails['to']?.details?.staffPositionId) {
                            setSearchFrom(`${details?.empid} | ${details?.empName} | ${details?.staffPositionId}`)
                    } else {
                        setSearchFromError(true)
                        setFromErrorMsg(partyDetails[key]?.details?.length === 0 ? `${getOptionName(fromSearchOptionSelected)} not found` : `${getOptionName(fromSearchOptionSelected)} cannot be same`);
                    }
                } else {
                    if(details?.length > 1){
                        setShowToSuggestion(true);
                        setToSuggestionList(details);
                        return;
                    }
                    if (details?.staffPositionId && details?.staffPositionId !== partyDetails['from']?.details?.staffPositionId) {
                            setSearchTo(`${details?.empid} | ${details?.empName} | ${details?.staffPositionId}`)
                    } else {
                        setSearchToError(true)
                        setToErrorMsg(partyDetails[key]?.details?.length === 0 ? `${getOptionName(toSearchOptionSelected)} not found` : `${getOptionName(toSearchOptionSelected)} cannot be same`);
                    }
                }
            }
        }
        
        if(partyDetails['from']?.details?.divisionId && partyDetails['to']?.details?.divisionId 
            && partyDetails['from']?.details?.divisionId !== partyDetails['to']?.details?.divisionId) {
            setSearchToError(true)
            setToErrorMsg('Division ID cannot be different');
        } else if(partyDetails['from']?.details?.roleId && partyDetails['to']?.details?.roleId 
            && partyDetails['from']?.details?.roleId !== partyDetails['to']?.details?.roleId) {
            setSearchToError(true)
            setToErrorMsg('Role ID cannot be different');
        }
    }, [partyDetails])

    useEffect(() => {
        if (partiesSelectedForTransfer?.length > 0) {
            const doctorsCount = partiesSelectedForTransfer?.filter((party: any) => party?.partyTypes?.partyTypeGroup?.name === PARTY_TYPE.DOCTOR)
            const chemistCount = partiesSelectedForTransfer?.filter((party: any) => party?.partyTypes?.partyTypeGroup?.name === PARTY_TYPE.CHEMIST)

            setPartiesSelected(`${doctorsCount?.length} ${doctorsCount?.length > 1 ? 'Doctors' : 'Doctor'} ${chemistCount?.length > 0 ? ', ' + chemistCount?.length + ' Chemist' : ''}`)

        }
    }, [partiesSelectedForTransfer])

    const handleTransferRadioChange = (
        event: any,
    ) => {
        setPositionType((event.target as HTMLInputElement).value);
        dispatch(positionManagementStateActions.setPositionType((event.target as HTMLInputElement).value))
        resetFields();
    };

    const handleCloneChange = (event: any) => {
        setPositionType(event.target.value);
        dispatch(positionManagementStateActions.setPositionType(event.target.value))
        resetFields();
    };

    const resetFields = () => {
        setSearchFromError(false)
        setSearchToError(false)
        setPeriod(0);
        setSearchFrom('');
        setSearchTo('');
        dispatch(positionManagementStateActions.setPartiesDetails(positionManagementInitialState.partyDetails));
        setPartiesSelected('');
        dispatch(positionManagementStateActions.setPartiesSelectedForTransfer([]));
        dispatch(positionManagementStateActions.setValidateTransferResponse(null));
        setFromSearchOptionSelected(1)
        setToSearchOptionSelected(1)
    }

    const isValid = (val:any,type:any) => {
        if((type === 'from' && fromSearchOptionSelected === 1) || (type=== 'to' && toSearchOptionSelected === 1)){
            return parseInt(val) <= 2147483647
        }
        return true;
    }

    const handleSearchTextChange = (value: any, type: string) => {
        let regex = null;
        if(type === 'from'){
            regex = getOptionKey(fromSearchOptionSelected) === 'employeeName' ? /^[a-zA-Z ]*$/ : /^[0-9\b]+$/
        }else{
            regex = getOptionKey(toSearchOptionSelected) === 'employeeName' ? /^[a-zA-Z ]*$/ : /^[0-9\b]+$/
        }
        if(value === '' || regex.test(value) && isValid(value,type)) {
            if (type === 'from') {
                setSearchFrom(value)

            } else {
                setSearchTo(value)
            }
            if(value) {
                const obj = { [type]: { ...partyDetails[type], searchText: value } }
                dispatch(positionManagementStateActions.setPartiesDetails({ ...partyDetails, ...obj }))
            }
        }
    };

    const handleSearchPress = (e: any, type: any) => {
        if (e.key === 'Enter' && partyDetails[type]?.searchText?.trim()?.length) {
            const keySelected =  getOptionKey(type === 'from' ? fromSearchOptionSelected : toSearchOptionSelected)
            dispatch(fetchPartyDetailBySpidCreator({ payload: {[keySelected as string]: partyDetails[type]?.searchText}, type }))
            if (type === 'from') {
                dispatch(positionManagementStateActions.setPartiesSelectedForTransfer([]))
                dispatch(positionManagementStateActions.setPartiesList([]))
            }
            setSearchFromError(false)
            setSearchToError(false)
        }
    };

    const handleChange = (event: any) => {
        const selectedPeriod = cloningTimePeriod?.find((obj:any) => obj?.id === event.target.value)?.name
        setPeriod(selectedPeriod);
        dispatch(positionManagementStateActions.setPartiesDetails({ ...partyDetails, cloningPeriod: event.target.value }))
    };

    const handleTransferPartiesModal = () => {
        if ((Object.keys(partyDetails['from']?.details).length !== 0) && positionType === POSITION_TYPE.transfer) {
            dispatch(positionManagementStateActions.setShowTransferPartiesModal(true))
        }
    }

    const handleClearClick = (type:any) => {
            const obj = {...partyDetails, [type]:{
                searchText: '',
                details: {}
            }}
            dispatch(positionManagementStateActions.setPartiesDetails({...obj}))
            if (type === 'from') {
                setSearchFrom('')
                setSearchFromError(false);
                setFromErrorMsg('');
                setPartiesSelected('');
                dispatch(positionManagementStateActions.setPartiesSelectedForTransfer([]));
                setShowFromSuggestion(false)
            } else {
                setSearchTo('')
                setSearchToError(false);
                setToErrorMsg('');
                setShowToSuggestion(false)
            }
    }

    const handleSearchOptionChange = (val:any,type:any) => {
        
        if(type === 'from'){
            setSearchFromError(false)
            setFromSearchOptionSelected(val)
            setSearchFrom('')          
            dispatch(positionManagementStateActions.setPartiesDetails({ ...partyDetails,'from':{searchText:'',details:{}} }))  
        }else{
            setSearchToError(false)
            setToSearchOptionSelected(val)
            setSearchTo('')
            dispatch(positionManagementStateActions.setPartiesDetails({ ...partyDetails,'to':{searchText:'',details:{}} }))  

        }
    }

    const searchFilterOption = (type:any) => {
        return (
            <div className={classes.searchRadioBtns}>
                <RadioGroup
                        name={`${type}-radio-btns`}
                        value={type === 'from'? fromSearchOptionSelected : toSearchOptionSelected}
                        row
                    >
                {seachFilterOptions?.map((option) => 
                        <FormControlLabel
                            value={option.value}
                            control={<Radio className={classes.optionRadio}/>}
                            label={option.name}
                            onChange={() => handleSearchOptionChange(option?.value,type)}
                            data-testid={`${type}-${option.key}-radio`}
                            key={option.key}
                            className={classes.formLabel}
                        />
                    )}
                </RadioGroup>
            </div>
        )
    }

    const getOptionName = (value:any) => {        
         const selectedOption = seachFilterOptions.find((option:any) => {
            return option?.value === value
        })
        return selectedOption?.name
    }

    const getOptionKey = (value:any) => {        
        const selectedOption = seachFilterOptions.find((option:any) => {
           return option?.value === value
       })
       return selectedOption?.key
   }

   const handleSuggestionSelected = (type:any,value:any) => {
    setShowFromSuggestion(false);
    setShowToSuggestion(false);
    dispatch(positionManagementStateActions.setPartiesDetails({...partyDetails,[type]:{...partyDetails[type],details:value}}))
   }

    return (
        <div className={classes.container}>
            <div className={classes.flexSpaceBetween}>
                <Label
                    title={navbarComponentHeading}
                    variant="h6"
                    testID={'superman_admin_navbar_heading'}
                    textColor={'#322b7c'}
                    fontWeight={'800'}
                    hideToolTip
                />
            </div>
            <div className={classes.radioBtns}>
                <RadioGroup
                    name="controlled-radio-buttons-group"
                    value={positionType}
                    row
                >
                    <FormControlLabel
                        value={POSITION_TYPE.transfer}
                        control={<Radio />}
                        label={POSITION_TYPE.transfer}
                        data-testid={"transfer-radio"}
                        onChange={handleTransferRadioChange}
                    />
                    <FormControlLabel
                        value={POSITION_TYPE.clone}
                        control={<Radio />}
                        label={POSITION_TYPE.clone}
                        onChange={handleCloneChange}
                        data-testid={'clone-radio'}
                    />
                </RadioGroup>
            </div>
            <div className={classes.searchBarContainer}>
                <div className={classes.searchBarContent}>
                    <SearchBar
                        searchText={searchFrom}
                        handleChange={(e) => handleSearchTextChange(e, 'from')}
                        handleSearch={(e) => handleSearchPress(e, 'from')}
                        showError={searchFromError}
                        placeHolder={`${getOptionName(fromSearchOptionSelected)} (from)`}
                        errorMessage={fromErrorMsg}
                        disabled={Object.keys(partyDetails?.from?.details).length > 0}
                        handleClear={() => handleClearClick('from')}
                        showSuggestion={showFromSuggestion}
                        suggestionList={fromSuggestionList}
                        suggestionSelected={(e:any) => handleSuggestionSelected('from',e)}
                    />
                    {searchFilterOption('from')}
                </div>
                <ArrowForwardIosIcon className={classes.arrowIcon} />
                <div className={classes.searchBarContent}>
                    <SearchBar
                        searchText={searchTo}
                        handleChange={(e) => handleSearchTextChange(e, 'to')}
                        handleSearch={(e) => handleSearchPress(e, 'to')}
                        showError={searchToError}
                        placeHolder={`${getOptionName(toSearchOptionSelected)} (to)`}
                        errorMessage={toErrorMsg}
                        disabled={Object.keys(partyDetails?.to?.details).length > 0}
                        handleClear={() => handleClearClick('to')}
                        showSuggestion={showToSuggestion}
                        suggestionList={toSuggestionList}
                        suggestionSelected={(e:any) => handleSuggestionSelected('to',e)}
                    />
                    {searchFilterOption('to')}
                </div>
            </div>
            <div className={classes.bottomContainer}>
                <div className={classes.partiesContainer}>
                    {positionType === POSITION_TYPE.transfer && 
                        <IconButton color="primary" aria-label="transfer" component="label" onClick={handleTransferPartiesModal} data-testId={'transfer-parties-btn'}>
                            <GroupAddIcon color={positionType === 'Transfer' && partyDetails['from']?.details?.staffPositionId ? 'primary' : 'disabled'} />
                        </IconButton>
                    }
                    {positionType === POSITION_TYPE.transfer && partiesSelectedForTransfer?.length > 0 &&
                        <Button className={classes.partiesTextContainer}  onClick={handleTransferPartiesModal}>
                            <Label
                                title={partiesSelected}
                                testID={'superman_admin_navbar_heading'}
                                textColor={'#322b7c'}
                                size={11}
                                fontWeight={'600'}
                                classes={classes.partyCount}
                                hideToolTip />
                            <Label
                                title={' and associated chemists selected for transfer'}
                                testID={'superman_admin_navbar_heading'}
                                textColor={'#322b7c'}
                                fontWeight={'500'}
                                size={11}
                                classes={classes.selectedText}
                                hideToolTip
                            />

                        </Button>
                    }
                </div>
                {positionType !== POSITION_TYPE.transfer && 
                    <div>
                        <FormControl>
                            <Select
                                id="clone-period"
                                value={period}
                                data-testid={"clone-period"}
                                onChange={handleChange}
                                className={classes.dropdown}
                                inputProps={{ 'aria-label': 'Without label' }}
                                renderValue={(selected) => {
                                    if (selected === 0) {
                                        return 'Select Period';
                                    }
                                    return selected;
                                }}
                            >
                                <MenuItem disabled value="0">
                                    <em>Select Period</em>
                                </MenuItem>
                                {cloningTimePeriod?.map((period: any) =>
                                    <MenuItem value={period?.id} data-testid={period?.name}>{period?.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </div>
                }
            </div>
        </div >
    )
}
