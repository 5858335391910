import {useEffect} from 'react';
import {routeSelector} from '@app/router/redux';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
export const ComponentManagement = () => {
  const appRoutesComponentManagement = useSelector(routeSelector.getAppRoutes());
  const navigate = useNavigate();
  useEffect(() => {
    let selectedRouteComponentManagement: any = {};
    appRoutesComponentManagement.map((route: any) => {
      if (route.webPath === '/component-management') {
        selectedRouteComponentManagement = route;
      }
      return null;
    });
    if (
      selectedRouteComponentManagement?.firstLevel?.length > 0
    ) {
      const firstRoute: any = selectedRouteComponentManagement.firstLevel[0];
      navigate(firstRoute.webPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRoutesComponentManagement]);

  return <div></div>;
};
