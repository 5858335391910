import { lazy } from 'react';
import { MasterDataManagement } from '@app/screens/master-data-management';
import { ExpenseDAMasterConetent } from '@app/screens/master-data-management/pages/expense-da-master';
import { Expense } from '@app/screens/module-specific-utilities/pages/expense';
import { ExpenseTAMaster } from '@app/screens/master-data-management/pages/expense-ta-master';
import { ComponentManagement } from '@app/screens/component-management';
import { ExpenseType } from '@app/screens/component-management/pages/expense-type';
import { Expenses } from '@app/screens/configuration-management/pages/expenses';
import { ConfigurationManagement } from '@app/screens/configuration-management';
import { ReverseExpense } from '@app/screens/module-specific-utilities/pages/status-manager';
import { BillLibrary } from '@app/screens/module-specific-utilities/pages/bill-library';
import { GenerateReport } from '@app/screens/module-specific-utilities/pages/generateReport';
import { ExpenseDate } from '@app/screens/configuration-management/pages/expenseDate';
import ColumnManagement from '@app/screens/directory-configuration/pages/column-management';
import ColumnMapping from '@app/screens/directory-configuration/pages/column-mapping';
import DepartmentManagement from '@app/screens/directory-configuration/pages/department-management';
import DirectoryConfiguration from '@app/screens/directory-configuration';
import HOContactCategory from '@app/screens/directory-configuration/pages/ho-contact-category';
import HOUser from '@app/screens/directory-configuration/pages/ho-user';
import SocialMedia from '@app/screens/directory-configuration/pages/social media';
import SocialMediaCategory from '@app/screens/directory-configuration/pages/social-media-category';
import TabManagement from '@app/screens/directory-configuration/pages/tab-management';

const Home = lazy(() =>
  import('@app/screens/home').then((module) => ({ default: module.Home }))
);
const Auth = lazy(() =>
  import('@app/screens/generic/Auth').then((module) => ({
    default: module.Auth,
  }))
);
const Login = lazy(() =>
  import('@app/screens/generic/Login').then((module) => ({
    default: module.Login,
  }))
);
const AccessManagement = lazy(() =>
  import('@app/screens/access-management').then((module) => ({
    default: module.AccessManagement,
  }))
);
const UserManagement = lazy(() =>
  import('@app/screens/access-management/pages/user-management').then(
    (module) => ({ default: module.UserManagement })
  )
);
const AccessGroupMaster = lazy(() =>
  import('@app/screens/access-management/pages/access-group-master').then(
    (module) => ({ default: module.AccessGroupMaster })
  )
);
const ModuleSpecificUtilities = lazy(() =>
  import('@app/screens/module-specific-utilities').then((module) => ({
    default: module.ModuleSpecificUtilities,
  }))
);
const ApproveExpenses = lazy(() =>
  import('@app/screens/module-specific-utilities/pages/approve-expenses').then(
    (module) => ({ default: module.ApproveExpenses })
  )
);

const PlanAndMeet = lazy(() =>
  import('@app/screens/module-specific-utilities/pages/plan-and-meet').then(
    module => ({default: module.PlanAndMeet}),
  ),
);
const DcrUnlockRequest = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/dcr-unlock-request'
  ).then(module => ({default: module.DcrUnlockRequest})),
);
const PositionManagement = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/position-management'
  ).then(module => ({default: module.PositionManagement})),
);
const ApprovalWorkflow = lazy(() =>
  import('@app/screens/configuration-management/pages/approval-workflow').then(
    module => ({default: module.ApprovalWorkflow}),
  ),
);
const ActivityMapping = lazy(() =>
  import('@app/screens/configuration-management/pages/activity-mapping').then(
    module => ({default: module.ActivityMapping}),
  ),
);
const Unauthorized = lazy(() =>
  import('@app/screens/unauthorized').then((module) => ({
    default: module.Unauthorized,
  }))
);
const InProgress = lazy(() =>
  import('@app/screens/in-progress').then((module) => ({
    default: module.InProgress,
  }))
);
const InventoryManagement = lazy(() =>
  import('@app/screens/inventory-management').then(module => ({
    default: module.InventoryManagement,
  })),
);
const IntransitChallan = lazy(() =>
  import('@app/screens/inventory-management/pages/intransit-challan').then(
    module => ({default: module.IntransitChallan}),
  ),
);
const InTransitAccess = lazy(() =>
  import('@app/screens/inventory-management/pages/in-transit-access').then(
    module => ({default: module.InTransitAccess}),
  ),
);
const ExpectedUtilizationDate = lazy(() =>
  import(
    '@app/screens/inventory-management/pages/Expected Utilizaton Date'
  ).then(module => ({default: module.ExpectedUtilizationDate})),
);
const InventoryRules = lazy(() =>
  import('@app/screens/inventory-management/pages/inventoryRules').then(
    module => ({default: module.InventoryRules}),
  ),
);
const CnfReturnItems = lazy(() =>
  import('@app/screens/inventory-management/pages/cnf-return-items').then(
    module => ({default: module.CnfReturnItems}),
  ),
);
const InventoryConfigurator = lazy(() =>
  import('@app/screens/inventory-management/pages/inventoryConfigurator').then(
    module => ({default: module.InventoryConfigurator}),
  ),
);
const DigitalTwinPartyMaster = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/digital-twin-party-master'
  ).then(module => ({ default: module.default })),
);

const MetricCategory = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/metric-category'
  ).then(module => ({ default: module.default })),
);
const MetricProperties = lazy(() =>
  import(
    '@app/screens/module-specific-utilities/pages/metric-properties'
  ).then(module => ({ default: module.default })),
);

export const RoutesName: any = {
  Login: {
    path: '/login',
    componentName: 'Login',
  },
  Auth: {
    path: '/auth',
    componentName: 'Auth',
  },
  Home: {
    path: '/',
    componentName: 'Home',
  },
  _Home: {
    path: '/home',
    componentName: 'Home',
  },
  Unauthorized: {
    path: '/unauthorized',
    componentName: 'Unauthorized',
  },
  InProgress: {
    path: '/in-progress',
    componentName: 'InProgress',
  },
};

export const RoutesNameComponentMap: any = {
  Home: <Home />,
  Login: <Login />,
  Auth: <Auth />,

  AccessManagement: <AccessManagement />,
  UserManagement: <UserManagement />,
  MasterDataManagement: <MasterDataManagement />,
  ExpenseDAMaster: <ExpenseDAMasterConetent />,
  BillLibrary: <BillLibrary />,
  AccessGroupMaster: <AccessGroupMaster />,
  ExpenseTAMaster: <ExpenseTAMaster />,
  ComponentManagement: <ComponentManagement />,
  Expenses: <Expenses />,
  ExpenseType: <ExpenseType />,
  ConfigurationManagement: <ConfigurationManagement />,
  Expense: <Expense />,
  Unauthorized: <Unauthorized />,

  InProgress: <InProgress />,
  PlanAndMeet: <PlanAndMeet />,
  DCRUnlockRequest: <DcrUnlockRequest />,
  PositionManagement: <PositionManagement />,
  ApprovalWorkflow: <ApprovalWorkflow />,
  InventoryManagement: <InventoryManagement />,
  IntransitChallan: <IntransitChallan />,
  InTransitAccess: <InTransitAccess />,
  CnfReturnItems: <CnfReturnItems />,
  InventoryConfigurator: <InventoryConfigurator />,
  ExpectedUtilizationDate: <ExpectedUtilizationDate />,
  InventoryRules: <InventoryRules />,
  ModuleSpecificUtilities: <ModuleSpecificUtilities />,
  ReverseExpense: <ReverseExpense />,
  GenerateReport: <GenerateReport />,
  ApproveExpenses: <ApproveExpenses />,
  ExpenseDate:<ExpenseDate/>,
  DirectoryConfiguration: <DirectoryConfiguration />,
  TabManagement: <TabManagement />,
  ColumnManagement: <ColumnManagement />,
  ColumnMapping: <ColumnMapping />,
  DepartmentManagement: <DepartmentManagement />,
  HOUser: <HOUser />,
  HOContactCategory: <HOContactCategory />,
  SocialMediaCategory: <SocialMediaCategory />,
  SocialMedia: <SocialMedia />,
  DigitalTwinPartyMaster: <DigitalTwinPartyMaster />,
  MetricCategory: <MetricCategory />,
  MetricProperties: <MetricProperties/>,
  ActivityMapping: <ActivityMapping />

};
