import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Stack,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Link,
  IconButton,
  styled,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {Search, KeyboardArrowDown} from '@mui/icons-material';
import {ChangeEvent, useCallback, useEffect, useState} from 'react';
import {
  actions,
  moduleSpecificUtilitiesNavActionHandlerCreator,
  moduleSpecificUtilitiesNavSearchQueryCreator,
  moduleSpecificUtilitiesSelector,
} from '@app/screens/module-specific-utilities/redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {fetchdDownloadExcelURLCreator} from '@app/screens/module-specific-utilities/pages/digital-twin-party-master/redux/slice';
import {digitalTwinPartyMasterSelector} from '@app/screens/module-specific-utilities/pages/digital-twin-party-master/redux';

const SearchTextField = styled(TextField)(
  ({theme}) => `
  input: {
    padding: 12px 30px 12px 0;
    color: ${theme.palette.primary.main};
    ::placeholder: ${theme.palette.primary.main};
  }
`,
);

const StyledIconButton = styled(IconButton)({
  position: 'fixed',
  top: '10px',
  right: '12px',
  backgroundColor: '#fff',
  borderRadius: '50%',
  boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
  zIndex: '1',
  '&:hover': {
    backgroundColor: '#eee',
  },
});

export const ModuleSpecificUtilitiesNavBar = () => {
  const [bulkUpload, setBulkUpload] = useState<null | HTMLElement>(null);
  const [currentNavTab, setCurrentNavTab] = useState<null | string>('All');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const title = useSelector(moduleSpecificUtilitiesSelector.getNavTitle());
  const action = useSelector(moduleSpecificUtilitiesSelector.getNavAction());
  const search = useSelector(moduleSpecificUtilitiesSelector.getNavSearch());
  const showTabs = useSelector(
    moduleSpecificUtilitiesSelector.getShowNavTabs,
    shallowEqual,
  );
  const loading = useSelector(
    moduleSpecificUtilitiesSelector.getLoading(),
    shallowEqual,
  );

  const onSearchHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      dispatch(
        moduleSpecificUtilitiesNavSearchQueryCreator(event.target.value),
      );
    },
    [],
  );

  const handleBulkUpload = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setBulkUpload(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setBulkUpload(null);
  }, []);

  const handleUploadExcel = useCallback(() => {
    handleClose();
    dispatch(
      moduleSpecificUtilitiesNavActionHandlerCreator({
        show: true,
        type: actions.uploadExcel,
        name: 'uploadExcel',
      }),
    );
  }, [actions]);

  const url = useSelector(
    digitalTwinPartyMasterSelector.getDownloadExcelURL(),
    shallowEqual,
  );

  const handleDownloadExcel = () => {
    window.open(String(url), '_blank');
  };

  const onChangeNavTab = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentNavTab(newValue);
  };

  const onActionHandler = useCallback(() => {
    handleCloseNewMetric();
    dispatch(
      moduleSpecificUtilitiesNavActionHandlerCreator({
        show: true,
        type: actions.addMetricProperties,
        name: 'MetricProperties',
      }),
    );
  }, [actions]);

  const onActionHandlerIsExisting = useCallback(() => {
    handleCloseNewMetric();
    dispatch(
      moduleSpecificUtilitiesNavActionHandlerCreator({
        show: true,
        type: actions.addMetricProperties,
        name: 'MetricProperties',
        isExisting: true,
      }),
    );
  }, [actions]);
  const handleAddNewMetricCategory = useCallback(() => {
    handleClose();
    dispatch(
      moduleSpecificUtilitiesNavActionHandlerCreator({
        show: true,
        type: action.type,
      }),
    );
  }, [action]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseNewMetric = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!loading && !url) {
      dispatch(fetchdDownloadExcelURLCreator({}));
    }
  }, [loading]);

  return (
    <Grid container justifyContent={'space-between'} sx={{minHeight: 110}}>
      <Grid
        item
        xs={12}
        display={'flex'}
        direction="row"
        justifyContent={'space-between'}>
        <Grid xs={4}>
          <Box
            sx={{
              mb: 4,
              mt: 1,
            }}>
            <Typography variant="title" color={'primary.main'}>
              {title}
            </Typography>
          </Box>
        </Grid>
        <Grid>
          <Stack direction="row" spacing={2} alignSelf={'flex-end'}>
            <Button
              style={{height: 43}}
              variant="outlined"
              onClick={handleAddNewMetricCategory}>
              {action.title}
            </Button>
            <div>
              <Button
                style={{height: 43}}
                variant="outlined"
                onClick={handleBulkUpload}
                endIcon={<KeyboardArrowDown />}>
                {'Bulk upload'}
              </Button>
              <Menu
                sx={{mt: 0.5}}
                anchorEl={bulkUpload}
                open={!!bulkUpload}
                onClose={handleClose}>
                <MenuItem onClick={handleUploadExcel}>Upload Excel</MenuItem>
                <MenuItem onClick={handleDownloadExcel}>
                  Download Excel Template
                </MenuItem>
              </Menu>
            </div>
            <Button
              style={{height: 43}}
              variant="contained"
              onClick={handleClickOpen}>
              Metric Properties
            </Button>
            <div>
              <Dialog
                PaperProps={{
                  style: {
                    position: 'fixed',
                    top: '0',
                    right: '0',
                    margin: '16px',
                    maxHeight: 120,
                    overflowY: 'hidden',
                  },
                }}
                hideBackdrop
                open={open}
                onClose={handleCloseNewMetric}
                aria-describedby="alert-dialog-slide-description">
                <Box sx={{backgroundColor: '#0096d0', position: 'reative'}}>
                  <DialogTitle
                    color="#fff"
                    id="alert-dialog-title"
                    sx={{
                      paddingBottom: 0,
                      paddingTop: -0.5,
                      fontSize: 8,
                    }}>
                    <span
                      style={{
                        fontFamily: 'Poppins',
                        textTransform: 'capitalize',
                        fontSize: 15,
                      }}>
                      Metric properties
                    </span>
                  </DialogTitle>
                  <StyledIconButton
                    aria-label="delete"
                    onClick={handleCloseNewMetric}>
                    <ClearIcon sx={{color: '#0096d0'}} />
                  </StyledIconButton>
                  <DialogContent sx={{paddingTop: 0, paddingBottom: 0}}>
                    <DialogContentText
                      color="#fff"
                      id="alert-dialog-slide-description">
                      <Link
                        component="button"
                        variant="body2"
                        color="#fff"
                        underline="always"
                        sx={{marginRight: 1}}
                        onClick={onActionHandler}>
                        <span
                          style={{
                            fontFamily: 'Poppins',
                            textTransform: 'capitalize',
                            fontSize: 12,
                          }}>
                          Add new metric
                        </span>
                      </Link>
                      <span
                        style={{
                          fontFamily: 'Poppins',
                          textTransform: 'capitalize',
                          fontSize: 12,
                        }}>
                        or
                      </span>
                      <Link
                        component="button"
                        variant="body2"
                        color="#fff"
                        underline="always"
                        sx={{marginLeft: 1}}
                        onClick={onActionHandlerIsExisting}>
                        <span
                          style={{
                            fontFamily: 'Poppins',
                            textTransform: 'capitalize',
                            fontSize: 12,
                          }}>
                          Edit existing metric
                        </span>
                      </Link>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{flexDirection: 'row-reverse', paddingTop: 0}}>
                    <Button
                      sx={{color: '#fff', paddingRight: 30}}
                      onClick={handleCloseNewMetric}>
                      Undo
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>
            </div>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        display={'flex'}
        direction="row"
        justifyContent={'space-between'}>
        <Grid xs={8}>
          {search ? (
            <SearchTextField
              variant="outlined"
              placeholder={search.placeholder}
              type="search"
              fullWidth
              onChange={onSearchHandler}
              value={search.query}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          ) : null}
        </Grid>
        <Grid>
          {showTabs ? (
            <Tabs
              value={currentNavTab}
              onChange={onChangeNavTab}
              sx={{border: 2, borderRadius: 20, backgroundColor: '#FFFFFF'}}>
              <Tab value="All" label="All" id="all" aria-controls="all" />
              <Tab value="MR" label="MR" id="mr" aria-controls="mr" />
              <Tab value="FLM" label="FLM" id="flm" aria-controls="flm" />
              <Tab value="SLM" label="SLM" id="slm" aria-controls="slm" />
            </Tabs>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
