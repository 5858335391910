import { createSelector } from "@reduxjs/toolkit";


const getMetricPropertiesLists = ( state: any) => state.metricPropertiesState.metricPropertiesList;


const getMetricPropertiesList = createSelector(
    [getMetricPropertiesLists],
    (_getMetricPropertiesLists) => _getMetricPropertiesLists
)

export const metricPropertiesSelector = {
    getMetricPropertieslist: () => getMetricPropertiesList,
};


