import Typography from '@mui/material/Typography';
import {Box, Button, FormControl, MenuItem, Select, Stack} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import {
  fetchCNFHeadquartersCreator,
  fetchCnfReturnItemsFilterCreator,
  fetchCNFStatesAndDivisionsCreator,
  inventoryModuleCnfActions,
} from '@app/screens/inventory-management/pages/cnf-return-items/redux/slice';
import {adminCnfSelector} from '@app/screens/inventory-management/pages/cnf-return-items/redux/selectors';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {appSelector} from '@app/store/selectors';
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  filterContainer: {
    width: '99%',
    height: 'auto',
    background: '#eee',
    top: '55px',
    left: '8px',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '8px',
    borderRadius: '8px',
  },
  formControl: {
    margin: '8px',
  },
  icon: {
    marginTop: '4px',
  },
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const CNFApprovalRequestNavbar = () => {
  const cnfStates = useSelector(adminCnfSelector.getCNFStates());
  const cnfHeadquarters = useSelector(adminCnfSelector.getCNFHeadquarters());
  const [cnfState, setSelectedCnfState] = useState([]);
  const [cnfHq, setSelectedCnfHeadquarter] = useState('');
  const selectedCnfState = useSelector(adminCnfSelector.getSelectedCnfState());
  const writeAccess = useSelector(appSelector.getUserInfo());
  const selectedCnfDivision = useSelector(
    adminCnfSelector.getSelectedCnfDivision(),
  );
  const [cnfStatedata, setCNFStateData] = useState([]);
  const [cnfdivisiondata, setCNFDivisionData] = useState([]);
  const [cnfHqdata, setCNFHqData] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCNFStatesAndDivisionsCreator());
  }, []);
  useEffect(() => {
    if (cnfStates?.stateData) {
      const States: any = [{id: 0, stateName: 'ALL'}, ...cnfStates.stateData];
      setCNFStateData(States);
    }
  }, [cnfStates]);
  const handleStateChange = (e: any) => {
    const CNF: any = cnfStates?.divisionData
      ? [{id: 0, name: 'ALL', shortName: 'ALL'}, ...cnfStates.divisionData]
      : [{id: 0, name: 'ALL', shortName: 'ALL'}];
    const StateID: any =
      e.target.value === 0
        ? cnfStatedata?.map((item: any) => item.id)
        : [e.target.value];
    setCNFDivisionData(CNF);
    dispatch(inventoryModuleCnfActions.setSelectedCnfState(StateID));
    setSelectedCnfState(StateID);
  };
  const handleHeadquarters = (e: any) => {
    const hqID: any =
      e.target.value === 0
        ? cnfHqdata?.map((item: any) => item.hqId)
        : [e.target.value];
    setSelectedCnfHeadquarter(hqID);
  };
  const handleGoClick = () => {
    const req = {
      stateId: selectedCnfState,
      divisionIds: selectedCnfDivision,
      hQsId: cnfHq,
    };
    dispatch(fetchCnfReturnItemsFilterCreator(req));
  };
  const handleDivisionChange = (e: any) => {
    const divID =
      e.target.value === 0
        ? cnfdivisiondata?.map((item: any) => item.id)
        : [e.target.value];
    {
      dispatch(inventoryModuleCnfActions.setSelectedCnfDivison(divID));
    }
    const reqPayload = {
      stateId: [...cnfState],
      divisionId: [...divID],
    };
    dispatch(fetchCNFHeadquartersCreator(reqPayload));
  };

  useEffect(() => {
    const CNFHq: any = [{hqId: 0, hqNames: 'ALL'}, ...cnfHeadquarters];
    setCNFHqData(CNFHq);
  }, [cnfHeadquarters]);
  const enableButton =
    selectedCnfState === '' || selectedCnfDivision === '' || cnfHq === '';
  return (
    <Box sx={styles.container}>
      <Stack spacing={2} direction="row">
        <Typography
          data-testid={'cnf return request approval'}
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontStyle={'normal'}>
          CNF Return Request Approval
        </Typography>
      </Stack>
      <Box sx={styles.filterContainer}>
        <FormControl sx={styles.formControl} fullWidth>
          <InputLabel id="select-state-label">Select State</InputLabel>
          <Select
            sx={{backgroundColor: '#ffffff'}}
            inputProps={{
              id: 'selectState-dropdown',
              'data-testid': 'selectState-dropdown',
            }}
            disableUnderline
            variant="outlined"
            labelId="select-state-label"
            onChange={handleStateChange}
            disabled={!writeAccess?.isWriteAccess}
            value={
              selectedCnfState && selectedCnfState.length > 1
                ? 0
                : selectedCnfState[0]
            }
            MenuProps={MenuProps}
            label="Select State">
            {cnfStatedata &&
              cnfStatedata != undefined &&
              cnfStatedata.length > 0 &&
              cnfStatedata.map((stateData: any) => {
                return (
                  <MenuItem value={stateData.id} key={stateData.value}>
                    {stateData.stateName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={styles.formControl} fullWidth>
          <InputLabel id="select-division-label">Select Division</InputLabel>
          <Select
            inputProps={{
              'data-testid': 'selectDivision-dropdown',
            }}
            sx={{backgroundColor: '#ffffff'}}
            variant="outlined"
            labelId="select-division-label"
            label="Select Division"
            disabled={!writeAccess?.isWriteAccess}
            onChange={(e: any) => handleDivisionChange(e)}
            MenuProps={MenuProps}
            value={
              selectedCnfDivision && selectedCnfDivision.length > 1
                ? 0
                : selectedCnfDivision[0]
            }>
            {cnfStates &&
              cnfdivisiondata != undefined &&
              cnfdivisiondata.length > 0 &&
              cnfdivisiondata?.map((divisionData: any) => {
                return (
                  <MenuItem value={divisionData.id} key={divisionData.value}>
                    {divisionData.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl sx={styles.formControl} fullWidth>
          <InputLabel id="select-hq-label">Select HeadQuarters</InputLabel>
          <Select
            sx={{backgroundColor: '#ffffff'}}
            inputProps={{
              'data-testid': 'selectHeadQuarters-dropdown',
            }}
            disableUnderline
            variant="outlined"
            labelId="select-hq-label"
            label="Select HeadQuarters"
            MenuProps={MenuProps}
            disabled={!writeAccess?.isWriteAccess}
            onChange={e => handleHeadquarters(e)}
            value={cnfHq && cnfHq.length > 1 ? 0 : cnfHq[0]}>
            {cnfHqdata &&
              cnfHqdata.length > 0 &&
              cnfHqdata.map((hqData: any) => {
                return (
                  <MenuItem value={hqData.hqId} key={hqData.hqId}>
                    {hqData.hqNames}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <Button
          data-testid={'go-button'}
          sx={styles.formControl}
          variant="contained"
          size="small"
          onClick={handleGoClick}
          disabled={!writeAccess?.isWriteAccess || enableButton}>
          Go
        </Button>
      </Box>
    </Box>
  );
};
