import { put, takeLatest } from "redux-saga/effects";
import {
  moduleSpecificUtilitiesActions,
  moduleSpecificUtilitiesUpdateCurrentPageCreatorTypeName,
  updateModuleSpecificUtilitiesNavDataCreatorTypeName,
  moduleSpecificUtilitiesNavToggleCreatorTypeName,
  moduleSpecificUtilitiesNavSearchQueryCreatorTypeName,
  moduleSpecificUtilitiesLoadingHandlerCreatorTypeName,
  moduleSpecificUtilitiesNavActionHandlerCreatorTypeName,
} from "./slice";

/**
 * Update Admin Nav watcher
 */
export function* updateModuleSpecificUtilitiesNavWatcher() {
  yield takeLatest(
    updateModuleSpecificUtilitiesNavDataCreatorTypeName,
    updateNavDataWorker
  );
}

/**
 * Update admin nav data
 *
 * @param action
 */
function* updateNavDataWorker(action: any) {
  yield put(moduleSpecificUtilitiesActions.updateNavData(action.payload));
}

/**
 * Update Admin Nav watcher
 */
export function* moduleSpecificUtilitiesNavActionHandlerWatcher() {
  yield takeLatest(
    moduleSpecificUtilitiesNavActionHandlerCreatorTypeName,
    navActionHandlerWorker
  );
}

function* navActionHandlerWorker(action: any) {
  yield put(moduleSpecificUtilitiesActions.updateModalHandler(action.payload));
}

/**
 * Update Admin Nav watcher
 */
export function* moduleSpecificUtilitiesLoadingHandlerWatcher() {
  yield takeLatest(
    moduleSpecificUtilitiesLoadingHandlerCreatorTypeName,
    loadingHandlerWorker
  );
}

function* loadingHandlerWorker(action: any) {
  yield put(moduleSpecificUtilitiesActions.updateLoading(action.payload));
}

export function* moduleSpecificUtilitiesNavSearchQueryWatcher(action: any) {
  yield takeLatest(
    moduleSpecificUtilitiesNavSearchQueryCreatorTypeName,
    navSearchQueryWorker,
  );
}

function* navSearchQueryWorker(action: any) {
  yield put(moduleSpecificUtilitiesActions.updateNavSearchQuery(action.payload));
}

export function* moduleSpecificUtilitiesNavToggleWatcher(action: any) {
  yield takeLatest(
    moduleSpecificUtilitiesNavToggleCreatorTypeName,
    navToggleWorker,
  );
}

function* navToggleWorker(action: any) {
  yield put(moduleSpecificUtilitiesActions.updateNavToggle(action.payload));
}

export function* moduleSpecificUtilitiesUpdateCurrentPageWatcher(action: any) {
  yield takeLatest(
    moduleSpecificUtilitiesUpdateCurrentPageCreatorTypeName,
    updateCurrentPageWorker,
  );
}

function* updateCurrentPageWorker(action: any) {
  yield put(moduleSpecificUtilitiesActions.updateCurrentPage(action.payload));
}
