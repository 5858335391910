import { filterStateActions } from "@app/components/right-sidebar/redux";
import { routeStateActions } from "@app/router/redux";
import { navbarComponentName } from "@app/router/redux/routeHandler";
import { fetchDivisionDropdownCreator } from "@app/screens/access-management/pages/user-management/redux/slice";
import { useStyles } from "@app/screens/Styles/style";
import { Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddExpenseTAMaster } from "../../components/ExpenseDAMasterPopup/addExpenseTAMaster";
import { ExpenseTAGrid } from "../../components/ExpenseDAMasterPopup/ExpenseTAMasterGrid/expenseTAMasterGrid";
import { expenseDAMasterStateActions } from "../expense-da-master/redux";
import {
  fetchDesignationsDropdownCreator,
  fetchHQDropdownCreator,
} from "../expense-da-master/redux/slice";
import { expenseTAMasterStateSelector } from "./redux/selectors";
import {
  expenseTAMasterStateActions,
  fetchExpenseTAMasterGridCreator,
} from "./redux/slice";
import { Filter } from "@app/components/widgets/filters";

export const ExpenseTAMaster = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const expenseTAMasterData = useSelector(
    expenseTAMasterStateSelector.getExpenseTAMasterGrid()
  );

  const addExpenseTAMasterPopupVisible = useSelector(
    expenseTAMasterStateSelector.getaddexpenseTAMasterPopupVisible()
  );
  useEffect(() => {
    dispatch(expenseDAMasterStateActions.setIsExpenseDaMaster(false));
    dispatch(expenseTAMasterStateActions.setIsExpenseTaMaster(true));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.MasterDataManagement
      )
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeading}>Expense TA Master</div>
      )
    );
    dispatch(filterStateActions.setFilterState({}));
    dispatch(filterStateActions.setFilterOptions({
      isActive: true,
      searchText: "",
      pageNumber: 0,
      pageLimit:100,
      divisionIds: [],
      designationIds: [],
      hqIds: []
    }))
    dispatch(fetchExpenseTAMasterGridCreator(null));
    dispatch(fetchDivisionDropdownCreator());
    dispatch(fetchHQDropdownCreator());
    dispatch(fetchDesignationsDropdownCreator());
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(expenseTAMasterStateActions.setIsExpenseTaMaster(false));
      dispatch(filterStateActions.setFilterState({}));
      dispatch(expenseTAMasterStateActions.setSearchText(""));
      dispatch(expenseDAMasterStateActions.setDesignationArr([]));
      dispatch(expenseDAMasterStateActions.setHqByDivisionDesignation([]));
      dispatch(expenseTAMasterStateActions.setExpenseTAMasterGrid([]));
      dispatch(
        expenseTAMasterStateActions.setExpenseTAMasterInAutoSuggestFilter({})
      );
      dispatch(expenseTAMasterStateActions.setExpenseTAMasterInAutoSuggest([]));
      dispatch(filterStateActions.setFilterOptions({
        isActive: true,
        searchText: "",
        pageNumber: 0,
        pageLimit:100,
        divisionIds: [],
        designationIds: [],
        hqIds: []
      }))
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.navbarComponentHeading, dispatch]);
  return (
    <Container maxWidth={false}>
      <Filter/>
        <ExpenseTAGrid gridData={expenseTAMasterData ?? []} />
      {addExpenseTAMasterPopupVisible && <AddExpenseTAMaster />}
    </Container>
  );
};
