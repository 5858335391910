import {API_PATH} from '@app/common/constants';
import {NetworkService} from '@app/services';
import {takeLatest, put, call} from 'redux-saga/effects';

import {
  expectedUtilizatonDateSkuAutoSuggestion,
  expectedUtilizatonDays,
  expectedUtilizatonDaysCreator,
  expectedUtilzationDateActions,
  newExpectedUtilizatonDay,
} from './slice';

export function* fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher() {
  yield takeLatest(
    expectedUtilizatonDateSkuAutoSuggestion,
    fetchExpectedUtilizatonDateSkuAutoSuggestionWorker,
  );
}
export function* fetchExpectedUtilizatonDateSkuAutoSuggestionWorker(
  action: any,
): any {
  let url = '';
  try {
    if (action.payload.radio === 'Sample') {
      url = `${API_PATH.expectedUtilizationDate.getautosuggestsku}?sampleName=${action.payload.event}`;
    } else {
      url = `${API_PATH.expectedUtilizationDate.getautosuggestitem}?itemName=${action.payload.event}`;
    }
    const autoSuggestSku = yield call(NetworkService.get, url, {}, {});
    yield put(
      expectedUtilzationDateActions.SetExpectedUtilizatonDateSkuAutoSuggestionData(
        autoSuggestSku?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchExpectedUtilizatonDaysWatcher() {
  yield takeLatest(expectedUtilizatonDays, fetchExpectedUtilizatonDaysWorker);
}

export function* fetchExpectedUtilizatonDaysWorker(action: any): any {
  yield put(expectedUtilzationDateActions.setExpectedUtilizatonDaysLoading());
  try {
    const url = `${API_PATH.expectedUtilizationDate.expectutilizationDays}`;
    const res = yield call(NetworkService.post, url, action.payload, null);
    console.log(res, 'expected');

    yield put(
      expectedUtilzationDateActions.setExpectedUtilizatonDaysData(res?.data),
    );
  } catch (e) {
    yield put(expectedUtilzationDateActions.setExpectedUtilizatonDaysError());
  }
}

export function* fetchNewExpectedUtilizatonDaysWatcher() {
  yield takeLatest(
    newExpectedUtilizatonDay,
    fetchNewExpectedUtilizatonDaysWorker,
  );
}

export function* fetchNewExpectedUtilizatonDaysWorker(action: any): any {
  yield put(
    expectedUtilzationDateActions.setNewExpectedUtilizatonDaysLoading(),
  );
  try {
    const url = `${API_PATH.expectedUtilizationDate.NewexpectutilizationDays}`;
    const res = yield call(NetworkService.post, url, action.payload, null);
    yield put(
      expectedUtilzationDateActions.setNewExpectedUtilizatonDaysData(res?.data),
    );
    yield put(expectedUtilizatonDaysCreator(action.payload));
  } catch (e) {
    yield put(
      expectedUtilzationDateActions.setNewExpectedUtilizatonDaysError(),
    );
  }
}
