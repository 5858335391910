import { API_PATH } from "@app/common/constants";
import { moduleSpecificUtilitiesActions } from "@app/screens/module-specific-utilities/redux";
import { ToasterService } from "@app/services";
import NetworkService from "@app/services/network/NetworkService";
import { ToastType } from "@app/services/toaster";
import { call, put, take, takeLatest } from "redux-saga/effects";
import {
  metricCategoryTypeName,
  metricCategoryActions,
  metricCategoryPageLists,
  metricCategoryDropdownTypeName,
} from "./slice";
/**
 * Fetch Metric Category List watcher
 */

export function* fetchMetricCategoryListWatcher() {
  yield takeLatest(metricCategoryTypeName, fetchMetricCategoryListWorker);
}

/**
 * Fetch Metric Category List Worker
 */

function* fetchMetricCategoryListWorker(action: any): any {
  const { index, isActive, query } = action.payload;
  yield put(
    moduleSpecificUtilitiesActions.updateLoading(
      API_PATH.metricCategory.metricCategoryList
    )
  );
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.metricCategory.metricCategoryList,
      { pageIndex: index, pageSize: 15, isActive, name: query || "" },
      {}
    );
    yield put(moduleSpecificUtilitiesActions.updateLoading(null));
    if (index === 1) {
      yield put(
        moduleSpecificUtilitiesActions.updateTotalRecords({
          listing: metricCategoryPageLists.metricCategory,
          count: response?.data[0]?.totalCount || 0,
          pageIndex: index,
        })
      );
      yield put(metricCategoryActions.fetchMetricCategoryList(response.data));
    } else {
      yield put(
        moduleSpecificUtilitiesActions.updateTotalRecords({
          listing: metricCategoryPageLists.metricCategory,
          pageIndex: index,
        })
      );
      yield put(metricCategoryActions.fetchMetricCategoryNewList(response.data));
    }
  } catch (error) {
    yield put(moduleSpecificUtilitiesActions.updateLoading(null));
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}


/**
 * Fetch Metric Category Dropdown watcher
 */

export function* fetchMetricCategoryDropdownWatcher() {
  yield takeLatest(metricCategoryDropdownTypeName, fetchMetricCategoryDropdownWorker);
}


/**
 * Fetch Metric Category Dropdown Worker
 */

function* fetchMetricCategoryDropdownWorker(action: any): any {
  const { flag } = action.payload;
  try {
    const response = yield call(
      NetworkService.get,
      API_PATH.metricCategory.metricCategoryDropdown,
      {flag: flag},
      {}
    );
    yield put(metricCategoryActions.fetchMetricCategoryDropdown(response.data));
  } catch (error) {
    ToasterService.showToaster(
      "Something went wrong while fetching admin data!",
      ToastType.ERROR
    );
  }
}