import { useStyles } from "@app/screens/Styles/style";
import { Button } from "@mui/material"

export const ExportButtons : React.FC<{isWriteAccess:boolean ,onClick : (e:any) => void,name:string}> = ({isWriteAccess,onClick,name}) => {
    const classes = useStyles();
    return(
        <Button
        name={name}
  variant="text"
  className={classes.button}
  data-testid="download_excel"
  onClick={onClick}
  startIcon={<img
    alt="excel"
    width={20}
    src={require('../../../assets/images/MSExcel.png')} />}
  sx={{
    ...(!isWriteAccess && { color: 'grey' }),
  }}
>Download Excel </Button>
    )
}