import { createAction, createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  parties: {}[];
  downloadExcelURL: '',
}

const initialState: initialStateProps = {
    parties: [],
    downloadExcelURL: '',
};

export const fetchPartiesCreator = createAction<any>("DIGITAL_TWIN_PARTY_MASTER/FETCH_PARTIES");

export const fetchPartiesTypeName = fetchPartiesCreator(null).type;

export const fetchdDownloadExcelURLCreator= createAction<any>("DIGITAL_TWIN_PARTY_MASTER/FETCH_DOWNLOAD_EXCEL_URL");

export const fetchDownloadExcelURLTypeName = fetchdDownloadExcelURLCreator(null).type;

const digitalTwinPartyMasterSlice = createSlice({
  name: "DIGITAL_TWIN_PARTY_MASTER",
  initialState: initialState,
  reducers: {
    setPartyMasters: (state, action) => {
      return {
        ...state,
        parties: action.payload,
      };
    },
    updatePartyMasters: (state, action) => {
      return {
        ...state,
        parties: [...state.parties, ...action.payload],
      };
    },
    setDownloadExcelURL: (state, action) => {
      return {
        ...state,
        downloadExcelURL: action.payload,
      };
    },
  },

});

export const digitalTwinPartyMasterActions = digitalTwinPartyMasterSlice.actions;
export const digitalTwinPartyMasterReducer = digitalTwinPartyMasterSlice.reducer;
