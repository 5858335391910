import {createAction, createSlice} from '@reduxjs/toolkit';

export const inventoryRolesInitialState = {
  selectedPage: 0,
  inventoryRolesData: [],
  itemCategory: [],
  inventoryRolesLoading: false,
  inventoryDivision: [],
  inventoryPartyType: [],
  rolesError: '',
};

export const fetchItemCategoryCreator = createAction('ITEM-CATEGORY');
export const fetchItemCategoryName = fetchItemCategoryCreator().type;

export const fetchInventoryRolesCreator = createAction('INVENTORY-ROLES-LIST');
export const fetchInventoryRolesName = fetchInventoryRolesCreator().type;

export const fetchInventoryDeleteRolesCreator = createAction<any>('INVENTORY-DELETE-ROLES-ITEM');
export const fetchInventoryDeleteRolesName = fetchInventoryDeleteRolesCreator({}).type;

export const fetchInventorySaveRolesCreator = createAction<any>('INVENTORY-SAVE-ROLES-ITEM');
export const fetchInventorySaveRolesName = fetchInventorySaveRolesCreator({}).type;

export const fetchInventoryDivitionCreator = createAction('INVENTORY-DIVISION-ITEM');
export const fetchInventoryDivisionName = fetchInventoryDivitionCreator().type;

export const fetchInventoryRolesPage2Creator = createAction('INVENTORY-ROLES-ITEM-PAGE2');
export const fetchInventoryRolesPage2Name = fetchInventoryRolesPage2Creator().type;

export const fetchInventoryDeleteRolesPage2Creator = createAction<any>('INVENTORY-DELETE-ROLES-ITEM-PAGE2');
export const fetchInventoryDeleteRolesPage2Name = fetchInventoryDeleteRolesPage2Creator({}).type;

export const fetchInventorySaveRolesPage2Creator = createAction<any>('INVENTORY-SAVE-ROLES-ITEM-PAGE2');
export const fetchInventorySaveRolesPage2Name = fetchInventorySaveRolesPage2Creator({}).type;

export const fetchInventoryPartyTypeCreator = createAction('INVENTORY-PARTY-TYPE');
export const fetchInventoryPartyTypeName = fetchInventoryPartyTypeCreator().type;

const inventoryRolesHandler = createSlice({
  name: 'INVENTORY-ROLES_HANDLER',
  initialState: inventoryRolesInitialState,
  reducers: {
    setSelectedPage: (prevState, {payload})=> {
      return {
        ...prevState,
        selectedPage: payload,
        inventoryRolesData: [],
        itemCategory: [],
      };
    },
    setInventoryRolesList: (prevState, {payload}) => {
      return {
        ...prevState,
        inventoryRolesData: payload,
        inventoryRolesLoading: false,
      };
    },
    setItemCategory: (prevState, {payload}) => {
      return {
        ...prevState,
        itemCategory: payload,
      };
    },
    setLoadingRoles: (prevState) => {
      return {
        ...prevState,
        inventoryRolesLoading: true,
      };
    },
    setRolesError: (prevState, {payload}) => {
      return {
        ...prevState,
        rolesError: payload,
        inventoryRolesLoading: false,
      };
    },
    setDivision: (prevState, {payload}) => {
      return {
        ...prevState,
        inventoryDivision: payload,
      };
    },
    setPartyType: (prevState, {payload}) => {
      return {
        ...prevState,
        inventoryPartyType: payload,
      };
    },
  },
});

export const inventoryRolesActions = inventoryRolesHandler.actions;
export const inventoryRolesReducer = inventoryRolesHandler.reducer;
