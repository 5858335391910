import {createAction, createSlice} from '@reduxjs/toolkit';

interface IApprovalWorkflowDivision {
  id: number;
  name: string;
  shortName: string;
  maxPatchCount: number;
  kycPartyLimit: number;
  divisionGroupId: number;
}

export interface IApprovalWorkflowListItem {
  division: IApprovalWorkflowDivision;
  feature: {
    id: number;
    name: string;
    isActive: boolean;
  };
  isActive: boolean;
  maxLevel: number;
  modifiedOn: string;
  requestorConfigurations: Array<{
    id: number;
    roleId: number;
  }>;
  approvalLevelConfigurations: Array<{
    id: number;
    level: number;
    slaInHrs: number;
  }>;
}
export interface IApprovalWorkflowInitialState {
  loader: boolean;
  searchText: string;
  gridOptions: any;
  totalRows: number;
  approvalWorkflowList: Array<any>;
  masterConfigData:any;
  editableField:any;
  createApprovalResponse:any;
  minApproverResponse: any;
}

export const approvalWorkflowInitialState: IApprovalWorkflowInitialState = {
  loader: false,
  searchText: '',
  gridOptions: {},
  totalRows: 0,
  approvalWorkflowList: [],
  masterConfigData:null,
  editableField:null,
  createApprovalResponse:null,
  minApproverResponse: null
};

export const fetchApprovalWorkflowListCreator = createAction(
  'APPROVAL_WORKFLOW_HANDLER/FETCH_APPROVAL_WORKFLOW_LIST',
);
export const fetchApprovalWorkflowListCreatorTypeName =
  fetchApprovalWorkflowListCreator().type;

export const fetchMasterConfigDataCreator = createAction(
  'APPROVAL_WORKFLOW_HANDLER/FETCH_MASTER_CONFIG_DATA',
)

export const fetchMasterConfigDataCreatorTypeName = fetchMasterConfigDataCreator().type;

export const createApprovalResponseCreator = createAction<any>(
  'APPROVAL_WORKFLOW_HANDLER/CREATE_APPROVAL_RESPONSE',
)

export const createApprovalResponseCreatorTypeName = createApprovalResponseCreator({}).type;

export const updateMinApproverConfigCreator = createAction<any>(
  'APPROVAL_WORKFLOW_HANDLER/UPDATE_MIN_APPROVER',
)

export const updateMinApproverConfigCreatorTypeName = updateMinApproverConfigCreator({}).type;

const approvalWorkflowHandler = createSlice({
  name: 'APPROVAL_WORKFLOW_HANDLER',
  initialState: approvalWorkflowInitialState,
  reducers: {
    setApprovalWorkflowList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        approvalWorkflowList: payload,
      };
    },
    setCloneApprovalWorkflowList: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        cloneApprovalWorkflowList: payload,
      };
    },
    setApprovalWorkflowLoader: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        loader: payload,
      };
    },
    setApprovalWorkflowSearchText: (prevState, action) => {
      return {
        ...prevState,
        searchText: action.payload,
      };
    },
    setApprovalWorkflowGridOptions: (prevState, action) => {
      return {
        ...prevState,
        gridOptions: action.payload,
      };
    },
    setTotalRowsInGrid: (prevState, action) => {
      return {
        ...prevState,
        totalRows: action.payload,
      };
    },
    resetApprovalWorkflowState: () => {
      return {
        ...approvalWorkflowInitialState,
      };
    },
    setCreateWorkflowScreen: (prevState,action) => {
      return {
        ...prevState,
        createWorkflowScreen: action.payload
      }
    },
    setMasterConifData: (prevState,action) => {
      return {
        ...prevState,
        masterConfigData:action.payload
      }
    },
    setEditableField: (prevState,action) => {
      return {
        ...prevState,
        editableField: action.payload
      }
    },
    setCreateApprovalResponse: (prevState, action) => {
      return {
        ...prevState,
        createApprovalResponse: action.payload
      }
    },
    setMinApproverResponse: (prevState, action) => {
      return {
        ...prevState,
        minApproverResponse: action.payload
      }
    }
  },
});

export const approvalWorkflowStateActions = approvalWorkflowHandler.actions;
export const approvalWorkflowStateReducer = approvalWorkflowHandler.reducer;
