import {createAction, createSlice} from '@reduxjs/toolkit';
export const inventoryManagementConfiguratorInitialState = {
  currentPageDefine: 'DEFINE',
  currentActivePage: true,
  inventoryConfiguratorDivisionLoading: true,
  inventoryConfiguratorDivisionSuccess: [],
  inventoryConfiguratorDivisionError: '',

  inventoryConfiguratorCustomerTypeLoading: true,
  inventoryConfiguratorCustomerTypeSuccess: [],
  inventoryConfiguratorCustomerTypeError: '',

  inventoryConfiguratorRegionHeirarchyLoading: true,
  inventoryConfiguratorRegionHeirarchySuccess: [],
  inventoryConfiguratorRegionHeirarchyError: '',

  inventoryConfiguratorDoctorSpecialityLoading: true,
  inventoryConfiguratorDoctorSpecialitySuccess: [],
  inventoryConfiguratorDoctorSpecialityError: '',

  inventoryConfiguratorDoctorQualificationLoading: true,
  inventoryConfiguratorDoctorQualificationSuccess: [],
  inventoryConfiguratorDoctorQualificationError: '',

  inventoryConfiguratorSpecCategoryLoading: true,
  inventoryConfiguratorSpecCategorySuccess: [],
  inventoryConfiguratorSpecCategoryError: '',

  inventoryConfiguratorRolesLoading: true,
  inventoryConfiguratorRolesSuccess: [],
  inventoryConfiguratorRolesError: '',

  inventoryConfiguratorClassCodeLoading: true,
  inventoryConfiguratorClassCodeSuccess: [],
  inventoryConfiguratorClassCodeError: '',

  inventoryConfiguratorItemCategoryLoading: true,
  inventoryConfiguratorItemCategorySuccess: [],
  inventoryConfiguratorItemCategoryError: '',

  inventoryConfiguratorItemCodeLoading: true,
  inventoryConfiguratorItemCodeSuccess: [],
  inventoryConfiguratorItemCodeError: '',

  inventoryConfiguratorSetValueLoading: true,
  inventoryConfiguratorSetValueSuccess :[],
  inventoryConfiguratorSetValueError: '',
};

export const inventoryConfiguratorDivisionCreator = createAction(
  'INVENTORY_CONFIGURATOR_DIVISION',
);
export const inventoryConfiguratorCustomerTypeCreator = createAction(
  'INVENTORY_CONFIGURATOR_CUSTOMER_TYPE',
);
export const inventoryConfiguratorRegionHeirarchyCreator = createAction(
  'INVENTORY_CONFIGURATOR_REGION_HEIRARCHY',
);
export const inventoryConfiguratorDoctorSpecialityCreator = createAction(
  'INVENTORY_CONFIGURATOR_DOCTOR_SPECIALITY',
);
export const inventoryConfiguratorDoctorQualificationCreator = createAction(
  'INVENTORY_CONFIGURATOR_DOCTOR_QUALIFICATION',
);
export const inventoryconfiguratorSpecCategoryCreator = createAction(
  'INVENTORY_CONFIGURATOR_SPEC_CATEGORY',
);
export const inventoryConfiguratorRolesCreator = createAction(
  'INVENTORY_CONFIGURATOR_ROLES',
);
export const inventoryConfiguratorClassCodeCreator = createAction(
  'INVENTORY_CONFIGURATOR_CLASSCODE',
);
export const inventoryConfiguratorItemCategoryCreator = createAction(
  'INVENTORY_CONFIGURATOR_ITEMCATEGORY'
)
export const inventoryConfiguratorItemCodeCreator = createAction(
  'INVENTORY_CONFIGURATOR_ITEMCODE'
)
export const inventoryConfiguratorSetValueCreator = createAction(
  'INVENTORY_CONFIGURATOR_SET_VALUE'

)
  export const inventoryConfiguratorDivisionName =
  inventoryConfiguratorDivisionCreator().type;
  export const inventoryConfiguratorRolesName =
  inventoryConfiguratorRolesCreator().type;
  export const inventoryConfiguratorItemCodeName =
  inventoryConfiguratorItemCodeCreator().type;
  export const inventoryConfiguratorClassCodeName =
  inventoryConfiguratorClassCodeCreator().type;
  export const inventoryConfiguratorItemCategoryName = 
  inventoryConfiguratorItemCategoryCreator().type
  export const inventoryConfiguratorCustomerTypeName =
  inventoryConfiguratorCustomerTypeCreator().type;
  export const inventoryConfiguratorHeirarchyName =
  inventoryConfiguratorRegionHeirarchyCreator().type;
  export const inventoryConfiguratorDoctorSpecialityName =
  inventoryConfiguratorDoctorSpecialityCreator().type;
  export const inventoryConfiguratorDoctorQualifcationName =
  inventoryConfiguratorDoctorQualificationCreator().type;
  export const inventoryConfiguratorSpecCategoryName =
  inventoryconfiguratorSpecCategoryCreator().type;
  export const inventoryConfiguratorSetValueName =
  inventoryConfiguratorSetValueCreator().type;

const inventoryManagementConfiguratorStateHandler = createSlice({
  name: 'INVENTORY_CONFIGURATOR_MANAGEMENT_HANDLER',
  initialState: inventoryManagementConfiguratorInitialState,
  reducers: {
    setInventoryManagementConfiguratorPage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        currentPageDefine: payload,
      };
    },
    setInventoryManagementConfiguratorActivePage: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        currentActivePage: payload,
      };
    },

    setInventoryConfiguratorDivisionLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorDivisionLoading: true,
        inventoryConfiguratorDivisionSuccess: [],
        inventoryConfiguratorDivisionError: '',
      };
    },
    setInventoryConfiguratorDivisionSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorDivisionLoading: false,
        inventoryConfiguratorDivisionSuccess: payload,
        inventoryConfiguratorDivisionError: '',
      };
    },
    setinventoryConfiguratorDivisionError: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorDivisionLoading: false,
        inventoryConfiguratorDivisionSuccess: [],
        inventoryConfiguratorDivisionError: '',
      };
    },
    setInventoryConfiguratorCustomerTypeLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorCustomerTypeLoading: true,
        inventoryConfiguratorCustomerTypeSuccess: [],
        inventoryConfiguratorCustomerTypeError: '',
      };
    },
    setInventoryConfiguratorCustomerTypeSuccess: (prevState, action) => {
      const {payload} = action;

      return {
        ...prevState,
        inventoryConfiguratorCustomerTypeLoading: false,
        inventoryConfiguratorCustomerTypeSuccess: payload,
        inventoryConfiguratorCustomerTypeError: '',
      };
    },
    setInventoryConfiguratorCustomerTypeError: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorCustomerTypeLoading: false,
        inventoryConfiguratorCustomerTypeSuccess: [],
        inventoryConfiguratorCustomerTypeError: '',
      };
    },
    setInventoryConfiguratorRegionHeirarchyLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorRegionHeirarchyLoading: true,
        inventoryConfiguratorRegionHeirarchySuccess: [],
        inventoryConfiguratorRegionHeirarchyError: '',
      };
    },
    setInventoryConfiguratorRegionHeirarchySuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorRegionHeirarchyLoading: false,
        inventoryConfiguratorRegionHeirarchySuccess: payload,
        inventoryConfiguratorRegionHeirarchyError: '',
      };
    },
    setInventoryConfiguratorRegionHeirarchyError: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorRegionHeirarchyLoading: false,
        inventoryConfiguratorRegionHeirarchySuccess: [],
        inventoryConfiguratorRegionHeirarchyError: '',
      };
    },
    setInventoryConfiguratorDoctorSpecialityLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorDoctorSpecialityLoading: true,
        inventoryConfiguratorDoctorSpecialitySuccess: [],
        inventoryConfiguratorDoctorSpecialityError: '',
      };
    },
    setInventoryConfiguratorDoctorSpecialitySuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorDoctorSpecialityLoading: false,
        inventoryConfiguratorDoctorSpecialitySuccess: payload,
        inventoryConfiguratorDoctorSpecialityError: '',
      };
    },
    setInventoryConfiguratorDoctorSpecialityError: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorDoctorSpecialityLoading: false,
        inventoryConfiguratorDoctorSpecialitySuccess: [],
        inventoryConfiguratorDoctorSpecialityError: '',
      };
    },
    setInventoryConfiguratorDoctorQualificationLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorDoctorQualificationLoading: true,
        inventoryConfiguratorDoctorQualificationSuccess: [],
        inventoryConfiguratorDoctorQualificationError: '',
      };
    },
    setInventoryConfiguratorDoctorQualificationSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorDoctorQualificationLoading: false,
        inventoryConfiguratorDoctorQualificationSuccess: payload,
        inventoryConfiguratorDoctorQualificationError: '',
      };
    },
    setInventoryConfiguratorDoctorQualificationError: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorDoctorQualificationLoading: false,
        inventoryConfiguratorDoctorQualificationSuccess: [],
        inventoryConfiguratorDoctorQualificationError: '',
      };
    },
    setInventoryConfiguratorSpecCategoryLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorSpecCategoryLoading: true,
        inventoryConfiguratorSpecCategorySuccess: [],
        inventoryConfiguratorSpecCategoryError: '',
      };
    },
    setInventoryConfiguratorSpecCategorySuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorSpecCategoryLoading: false,
        inventoryConfiguratorSpecCategorySuccess: payload,
        inventoryConfiguratorSpecCategoryError: '',
      };
    },
    setInventoryConfiguratorSpecCategoryError: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorSpecCategoryLoading: false,
        inventoryConfiguratorSpecCategorySuccess: [],
        inventoryConfiguratorSpecCategoryError: '',
      };
    },
    setInventoryConfiguratorRolesLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorRolesLoading: true,
        inventoryConfiguratorRolesSuccess: [],
        inventoryConfiguratorRolesError: '',
      };
    },
    setInventoryConfiguratorRolesSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorRolesLoading: false,
        inventoryConfiguratorRolesSuccess: payload,
        inventoryConfiguratorRolesError: '',
      };
    },
    setInventoryConfiguratorRolesError: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorRolesLoading: false,
        inventoryConfiguratorDivisionSuccess: [],
        inventoryConfiguratorRolesError: '',
      };
    },
    setInventoryConfiguratorClassCodeLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorClassCodeLoading: true,
        inventoryConfiguratorClassCodeSuccess: [],
        inventoryConfiguratorClassCodeError: '',
      };
    },
    setInventoryConfiguratorClassCodeSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorClassCodeLoading: true,
        inventoryConfiguratorClassCodeSuccess: payload,
        inventoryConfiguratorClassCodeError: '',
      };
    },
    setInventoryConfiguratorClassCodeError: (prevState:any) => {
      return {
        ...prevState,
        inventoryConfiguratorClassCodeLoading: false,
        inventoryConfiguratorClassCodeSuccess: [],
        inventoryConfiguratorClassCodeError: '',
      };
    },
    setInventoryConfiguratorItemCategoryLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorItemCategoryLoading: true,
        inventoryConfiguratorItemCategorySuccess: [],
        inventoryConfiguratorItemCategoryError: '',
      };
    },
    setInventoryConfiguratorItemCategorySuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorItemCategoryLoading: false,
        inventoryConfiguratorItemCategorySuccess: payload,
        inventoryConfiguratorItemCategoryError: '',
      };
    },
    setInventoryConfiguratorItemCategoryError: (prevState:any) => {
      return {
        ...prevState,
        inventoryConfiguratorClassCodeLoading: false,
        inventoryConfiguratorClassCodeSuccess: [],
        inventoryConfiguratorClassCodeError: '',
      };
    },
    setInventoryConfiguratorItemCodeLoading: (prevState: any) => {
      return {
        ...prevState,
        inventoryConfiguratorItemCodeLoading: true,
        inventoryConfiguratorItemCodeSuccess: [],
        inventoryConfiguratorItemCodeyError: '',
      };
    },
    setInventoryConfiguratorItemCodeSuccess: (prevState, action) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorItemCodeLoading: false,
        inventoryConfiguratorItemCodeSuccess: payload,
        inventoryConfiguratorItemCodeError: '',
      };
    },
    setInventoryConfiguratorSetValueSuccess: (prevState:any, action:any) => {
      const {payload} = action;
      return {
        ...prevState,
        inventoryConfiguratorSetValueLoading: false,
        inventoryConfiguratorSetValueSuccess: payload,
        inventoryConfiguratorSetValueError: '',
      };
    },
    setInventoryConfiguratorItemCodeError: (prevState:any) => {
      return {
        ...prevState,
        inventoryConfiguratorItemCodeLoading: false,
        inventoryConfiguratorItemCodeSuccess:[],
        inventoryConfiguratorItemCodeError: '',
      };
    },
  },
});
export const inventoryManagementConfiguratorStateActions =
  inventoryManagementConfiguratorStateHandler.actions;
export const inventoryManagementConfiguratorStateReducer =
  inventoryManagementConfiguratorStateHandler.reducer;
