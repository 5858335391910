import { createAction, createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
  metricCategoryList: any;
  metricCategoryDropdown: any;
}
const initialState: initialStateProps = {
  metricCategoryList: null,
  metricCategoryDropdown: null,
};


export const metricCategoryPageLists = {
  metricCategory: "metricCategory",
};

export const metricCategoryListCreator = createAction<any>("METRIC_CATEGORY_LIST");

export const metricCategoryTypeName = metricCategoryListCreator(null).type;

export const metricCategoryDropdownCreator = createAction<any>(
  "METRIC_CATEGORY_DROPDOWN"
);
export const metricCategoryDropdownTypeName = metricCategoryDropdownCreator(null).type;

const metricCategorySlice = createSlice({
  name: "METRIC_CATEGORY",
  initialState: initialState,
  reducers: {
    fetchMetricCategoryList: (state, action) => {
      return {
        ...state,
        metricCategoryList: action.payload,
      };
    },
    fetchMetricCategoryNewList: (state, action) => {
      return {
        ...state,
        metricCategoryList: [
          ...state.metricCategoryList,
          ...action.payload,
        ],
      };
    },
    fetchMetricCategoryDropdown: (state, action) => {
      return {
        ...state,
        metricCategoryDropdown: action.payload,
      };
    },
  },

});

export const metricCategoryActions = metricCategorySlice.actions;
export const metricCategoryReducer = metricCategorySlice.reducer;
