import {createAction, createSlice} from '@reduxjs/toolkit';

export const intrasistChallanInitialState = {
  isOpenChallans: true,
  intransitChallanloading: false,
  voucherDataLoading: false,
  challanData: [],
  autoSuggestionData: [],
  filteredData: [],
  intransitChallanError: '',
  selectedChallans: [],
  recievedChallans: [],
  searchType: '',
  searchvalue: '',
};

export const fetchIntransistChallanCreator = createAction<any>(
  'IN-TRANSIST-CHALLAN',
);
export const inTransitionAutoSuggestCreator =
  createAction<any>('AUTO_SUGGESTION');
export const inTransitFilteredData = createAction<any>('FILTERED_DATA');
export const postOpenCloseChallanCreator = createAction<any>('POST_OPEN_CLOSE');
export const recieveChallansCreator = createAction<any>('RECIEVE_CHALLANS');
export const enableDisableIntransitChallansCreator = createAction<any>(
  'ENABLE-DISABLE_INTRANSIT_CHALLAN',
);
export const fetchVoucherDataCreator = createAction<any>('VOUCHER_NUMBER_DATA');

export const fetchIntransistChallanName = fetchIntransistChallanCreator(
  {},
).type;
export const fetchAutoSuggestion = inTransitionAutoSuggestCreator({}).type;
export const fetchFilteredData = inTransitFilteredData({}).type;
export const postOpenCloseChallanName = postOpenCloseChallanCreator({}).type;
export const enableDisableIntransitChallansName =
  enableDisableIntransitChallansCreator({}).type;
export const fetchVoucherNumberData = fetchVoucherDataCreator({}).type;
export const fetchRecieveChallans = recieveChallansCreator({}).type;

const intransistChallanHandler = createSlice({
  name: 'IN_TRANSIST-CHALLAN_HANDLER',
  initialState: intrasistChallanInitialState,
  reducers: {
    setIsOpenChallans: prevState => {
      return {
        ...prevState,
        isOpenChallans: !prevState.isOpenChallans,
        selectedChallans: [],
      };
    },
    setIntransistChallanInit: prevState => {
      return {
        ...prevState,
        intransitChallanloading: true,
      };
    },
    setIntransistChallansSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        challanData: payload,
        selectedChallans: [],
        intransitChallanloading: false,
      };
    },
    setIntransistChallanError: (prevState, {payload}) => {
      return {
        ...prevState,
        intransitChallanloading: false,
        intransitChallanError: payload,
      };
    },
    setAutoSuggestionsData: prevState => {
      return {
        ...prevState,
        autoSuggestionData: [],
      };
    },
    setAutoSuggestionDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        autoSuggestionData: payload,
      };
    },
    setInTransitFilteredData: prevState => {
      return {
        ...prevState,
        filteredData: [],
      };
    },
    setFilteredDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        filteredData: payload,
      };
    },
    setSelectedIntransitChallans: (prevState, {payload}) => {
      return {
        ...prevState,
        selectedChallans: payload,
      };
    },
    setVoucherNumberData: (prevState, {payload}) => {
      return {
        ...prevState,
        voucherNumber: payload,
        voucherDataLoading: false,
      };
    },
    setRecievedChallansData: (prevState, {payload}) => {
      return {
        ...prevState,
        recievedChallans: payload,
        intransitChallanloading: false,
      };
    },
    setSearchTypeAndSearchValue: (prevState, {payload}) => {
      return {
        ...prevState,
        searchType: payload.searchType,
        searchValue: payload.searchValue,
      };
    },
  },
});

export const intransistChallanActions = intransistChallanHandler.actions;
export const intransistChallanReducer = intransistChallanHandler.reducer;
