import {useEffect, useState} from 'react';
import {Autocomplete, Box, FormControl, InputAdornment} from '@mui/material';
import {Search} from '@mui/icons-material';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import {useDispatch, useSelector} from 'react-redux';
import {
  inTransitionAutoSuggestCreator,
  inTransitFilteredData,
  intransistChallanActions,
  fetchIntransistChallanCreator,
} from '@app/screens/inventory-management/pages/intransit-challan/redux/slice';
import {trackEvent} from '@app/utils/analytics';
import {EVENT_NAME_SEARCH_BAR} from '@app/utils/analytics/constants';
import {navbarStyles} from '../widgets/AntSwitch/AntSwitch';
import {intransistChallanSelector} from '@app/screens/inventory-management/pages/intransit-challan/redux/selectors';
import _ from 'lodash';


export enum SearchType {
  divisionname = 'divisionname',
  staffname = 'staffname',
  challanNumber = 'challanNumber',
  staffCode = 'staffCode',
  state = 'state',
}
const searchTypeLabel = {
  divisionname: 'Division Name',
  staffname: 'Name',
  challanNumber: 'Challan Number',
  staffCode: 'Staff Code',
  state: 'State Name',
};

const styles = {
  formContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '24px',
  },
  formControl: {
    margin: '0 8px',
    backgroundColor: '#ffffff',
    borderWidth: 0.7,
    borderColor: '#322b7c',
  },
  suggestionsContainer: {
    left: '50%',
    backgroundColor: '#d5e2e7',
    width: '32%',
    padding: '20px',
    position: 'fixed',
    marginTop: '55px',
  },
};

export const SearchBarInventoryModule = ({autoSuggestionData}: any) => {
  const [searchType, setSearchType] = useState<SearchType | undefined>(
    undefined,
  );
  const isOpenChallans = useSelector(
    intransistChallanSelector.getIsOpenChallans(),
  );
  const [searchValue, setSearchValue] = useState<string>();
  const [searchText, setSearchText] = useState<string>('');
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOnSearchTypeChange = (e: SelectChangeEvent) => {
    setSearchType(e.target.value as SearchType);
    setSearchText('');
    setSearchValue('');
  };

  const onTextChange = (event: any) => {
    if(searchType){
      const {value} = event.target;
      setSearchText(value);
      trackEvent(EVENT_NAME_SEARCH_BAR, {});
      dispatch(
        inTransitionAutoSuggestCreator({
          searchType,
          searchValue: value,
          isOpenChallans: isOpenChallans,
        }),
      );
    }
  }
  const debouncedCallbackChange = _.debounce(onTextChange, 800);

  useEffect(() => {
    dispatch(intransistChallanActions.setInTransitFilteredData());
    dispatch(
      intransistChallanActions.setSearchTypeAndSearchValue({
        searchType: '',
        searchValue: '',
      }),
    );
    setSearchText('');
    setSearchValue('');
  }, [isOpenChallans]);

  return (
    <Box sx={styles.formContainer}>
      <FormControl sx={styles.formControl} fullWidth>
        <InputLabel id="search-type-select-label">Search By</InputLabel>
        <Select
          inputProps={{
            'data-testid': 'dropdown-search-type',
          }}
          key={isOpenChallans}
          variant="outlined"
          labelId="search-type-select-label"
          label="Search By"
          sx={{width: '100%'}}
          value={searchType}
          onChange={handleOnSearchTypeChange}>
          <MenuItem value={SearchType.divisionname}>
            {searchTypeLabel[SearchType.divisionname]}
          </MenuItem>
          <MenuItem value={SearchType.staffname}>
            {searchTypeLabel[SearchType.staffname]}
          </MenuItem>
          <MenuItem value={SearchType.challanNumber}>
            {searchTypeLabel[SearchType.challanNumber]}
          </MenuItem>
          <MenuItem value={SearchType.staffCode}>
            {searchTypeLabel[SearchType.staffCode]}
          </MenuItem>
          <MenuItem value={SearchType.state}>
            {searchTypeLabel[SearchType.state]}
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={styles.formControl} fullWidth>
        <Autocomplete
          data-testid="autocomplete-text"
          key={isOpenChallans}
          value={searchValue}
          open={open}
          sx={{width: '100%'}}
          onChange={(_event, value) => {
            if (!value) {
              dispatch(intransistChallanActions.setInTransitFilteredData());
              dispatch(
                fetchIntransistChallanCreator({isOpenClose: isOpenChallans}),
              );
              dispatch(
                intransistChallanActions.setSearchTypeAndSearchValue({
                  searchType: '',
                  searchValue: '',
                }),
              );
              setSearchValue('');
            }
            if (value) {
              setSearchValue(value);
              dispatch(
                inTransitFilteredData({
                  searchType,
                  searchValue: value,
                  isOpenChallans: isOpenChallans,
                }),
              );
              dispatch(
                intransistChallanActions.setSearchTypeAndSearchValue({
                  searchType: searchType && searchTypeLabel[searchType],
                  searchValue: value,
                }),
              );
            }
          }}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option: any, value: any) => {
            return option.name === value.name;
          }}
          getOptionLabel={(option: any) => {
            return option;
          }}
          options={
            autoSuggestionData?.length > 0 && searchText.length >= 2
              ? autoSuggestionData
              : []
          }
          renderInput={params => (
            <TextField
              data-testid="search-texfield"
              onChange={debouncedCallbackChange}
              {...params}
              placeholder={'Search here...'}
              sx={navbarStyles.textField}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start" sx={navbarStyles.padding5}>
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </FormControl>
    </Box>
  );
};
