import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@mui/styles';
import {routeSelector} from '@app/router/redux';
import { billLibraryStateSelector } from '@app/screens/module-specific-utilities/pages/bill-library/redux/selectors';
import ArrowCircleLeftOutlinedIcon  from '@mui/icons-material/ArrowCircleLeftOutlined';
import { billLibraryStateActions } from '@app/screens/module-specific-utilities/pages/bill-library/redux';
import { useCallback, useEffect } from 'react';
import { appSelector } from '@app/store/selectors';
import Colors from '@app/themes/colors';
import { Button } from '@mui/material';
import { kebabCase } from 'lodash';
import { CSVLink } from 'react-csv';
import { generateReportStateSelector } from '@app/screens/module-specific-utilities/pages/generateReport/redux/selectors';
import { appSliceActions } from '@app/store/reducers/appSlice';
import moment from 'moment';
const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  button: {
    borderRadius: '5px',
    fontStyle: 'normal',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    fontSize: 12.7,
    minWidth:"0px",
  },
});
export const CommonNavBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  const { isWriteAccess = false } = useSelector(
    appSelector.getUserInfo()
  );
  const generateReportGrid = useSelector(
    generateReportStateSelector.getGenerateReport(),
  );

  const excelInfo = useSelector(
    appSelector.getExcelData(),
  );
  const tabViewv= useSelector(
    billLibraryStateSelector.getGridVeiw()
  );
  const GridVeiw = useCallback(() => dispatch(
    billLibraryStateActions.setGridVeiw(false)),[]);
    useEffect(() => {
      dispatch(appSliceActions.setExcelData({
        data:generateReportGrid?.map((item:any)=>{
          return {...item,["passedOnDate"]:moment(item?.passedOnDate).format("DD/MM/YYYY")}}),
        headers: [
          { label: 'DIVISION', key: 'divisionName' },
          { label: 'STATE', key: 'stateName' },
          { label: 'SAP CODE', key: 'staffSAPCode' },
          { label: 'STAFF NAME', key: 'staffName' },
          { label: 'STAFF CODE', key: 'staffCode' },
          { label: 'DESIGNATION NAME', key: 'staffDesignationName' },
          { label: 'HQ', key: 'hqName' },
          { label: 'EXP MONTH', key: 'expenseMonth' },
          { label: 'KM', key: 'totalDistance' },
          { label: 'TA AMOUNT', key: 'adminApprovedTAAmount' },
          { label: 'DA AMOUNT', key: 'adminApprovedDAAmount' },
          { label: 'DA DAYS', key: 'expenseDADays' },
          { label: 'PASSED ON', key: 'passedOnDate' },
           { label: 'PASSED BY', key: 'passedByName' },
        ]
      
      }))
    },[generateReportGrid]);
  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#1c1939'}
          fontWeight={'800'}
          fontFamily={'Poppins'}
          fontStyle={'normal'}
          display= {"flex"}
        >
         { tabViewv ?<ArrowCircleLeftOutlinedIcon style={{margin:"3px",fontSize:"24px"}}  onClick={GridVeiw}/>:''}
        {navbarComponentHeading}
        </Typography>
        { navbarComponentHeading?.props?.children === 'Generate Report' &&  <Button
            variant="text"
            className={classes.button}
            startIcon={<img
              alt="excel"
              width={20}
              src={require('../../../assets/images/MSExcel.png')}
            />}
            sx={{
              ...(!isWriteAccess && {color: 'grey'}),
            }}
          >
          <CSVLink
              filename={`${kebabCase(navbarComponentHeading?.props?.children)}.csv`}
              data={excelInfo?.data || []}
              style={{ color: Colors.primary, textDecoration: 'none' }}
              headers={excelInfo?.headers || []}
            >
              Download Excel
            </CSVLink>
          </Button>}
      </div>
    </div>
  );
};
