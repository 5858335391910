import {store} from '@app/App';
import {
  APPROVALSTATUSKEY,
  DCRSTATUSKEY,
  DCRTYPEKEY,
  DIVISIONSKEY,
  HEADQUARTERSKEY,
  LOCKUNLOCKSTATUSKEY,
  REGIONSKEY,
  ZONESKEY,
} from '@app/common/constants';
import {IDCRUnlockRequest} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/slice';

export const capitalize = (input: any) => {
  input = input === undefined || input === null ? '' : input;

  return input
    .toString()
    .toLowerCase()
    .replace(/(^|\.|\s *)([a-z])/g, function (match: any) {
      return match.toUpperCase();
    });
};

export const extractDropdownID = (input:any ,data:any) => {
  const numArray = [];
    for (let i = 0; i < input.length; i++) {
      let number = data.filter(
        (item: any) => item.label === input[i],
      );
      numArray.push(...number);
    }
    const filterData:any = numArray.map(item => item.value); 
    return filterData
}

export const getDcrUnlockRequestObject = (state: any) => {
  return {
    pageNo: state?.gridOptions?.pageNo + 1 || 1,
    pageSize: state?.gridOptions?.pageSize || 10,
    isApproved: state?.isApprovedSelected,
    sortColumn:
      state?.gridOptions?.sortColumn?.toUpperCase() ||
      (state?.isApprovedSelected ? 'ApprovedDate' : ''),
    searchValue: state?.searchText,
    sortType:
      state?.gridOptions?.sortType || (state?.isApprovedSelected ? 'desc' : ''),
    filters: getAppliedFilter(state?.filters) || {},
  };
};

const getAppliedFilter = (filters: any) => {
  let updatedFilters = JSON.parse(JSON.stringify(filters ? filters : {}));

  updatedFilters[HEADQUARTERSKEY] = updatedFilters?.[HEADQUARTERSKEY]?.map(
    (hq: any) => hq.id,
  );

  const filterState: any = store?.getState()?.filterState?.filters;

  updatedFilters[APPROVALSTATUSKEY] = updatedFilters?.[APPROVALSTATUSKEY]?.map(
    (approvalStatusId: any) =>
      filterState?.approvalStatus?.filterData?.find(
        (item: any) => item?.id === approvalStatusId,
      ),
  )?.filter((status: any) => status);

  return updatedFilters;
};

export const createDCRFilterObject = (filters: any, selectedFilters: any) => {
  let appliedFilters: any = {};

  Object.keys(filters).forEach((filterKey: string) => {
    if (filterKey === DCRSTATUSKEY || filterKey === LOCKUNLOCKSTATUSKEY) {
      const res = filters[filterKey]?.filterData?.map((item: any) =>
        Boolean(item.isChecked),
      );
      appliedFilters[filterKey] = res?.length ? res : null;
    } else if (
      filterKey === APPROVALSTATUSKEY ||
      filterKey === DIVISIONSKEY ||
      filterKey === ZONESKEY ||
      filterKey === DCRTYPEKEY
    ) {
      const res = filters[filterKey]?.filterData
        ?.filter((item: any) => item?.isChecked)
        ?.map((item: any) => item.id);
      appliedFilters[filterKey] = res?.length ? res : null;
    }
  });

  const regions: any = [];
  const hqs: any = [];

  filters[ZONESKEY]?.filterData?.forEach((zone: any) => {
    if (!zone?.isChecked) {
      zone?.regions?.forEach((region: any) => {
        if (region?.isChecked) {
          regions.push(region.id);
        } else {
          region?.headquarters?.forEach((hq: any) => {
            if (hq?.isChecked) {
              hqs.push(hq);
            }
          });
        }
      });
    }
  });

  appliedFilters[REGIONSKEY] = regions?.length ? regions : null;
  appliedFilters[HEADQUARTERSKEY] = hqs?.length ? hqs : [];

  if (selectedFilters?.[HEADQUARTERSKEY]?.length) {
    selectedFilters?.[HEADQUARTERSKEY]?.forEach((hq: any) => {
      if (appliedFilters?.[HEADQUARTERSKEY]?.length) {
        const alreadyExists = appliedFilters?.[HEADQUARTERSKEY]?.find(
          (item: any) => item.id === hq.id,
        );

        if (!alreadyExists) {
          appliedFilters[HEADQUARTERSKEY]?.push(hq);
        }
      } else {
        appliedFilters[HEADQUARTERSKEY] = [...selectedFilters[HEADQUARTERSKEY]];
      }
    });
  }

  return appliedFilters;
};

export const sortDCRGridData = (gridData: Array<IDCRUnlockRequest>) => {
  return gridData
    ?.sort((a: IDCRUnlockRequest, b: IDCRUnlockRequest) => {
      return a?.approverId - b?.approverId;
    })
    ?.map((item, index) => ({...item, id: `${item?.requestId}-${index}`}));
};
