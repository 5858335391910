import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Stack,
  Switch,
  InputLabel,
  Autocomplete
} from "@mui/material";
import {
  directorySelector,
  directoryNavActionHandlerCreator,
  directoryNavSearchQueryCreator,
  directoryNavToggleCreator,
} from "@app/screens/directory-configuration/redux";
import { Search } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { ChangeEvent, useCallback } from "react";
import { directoryPageLists } from "@app/screens/directory-configuration/redux/slice";
import { columnManagementActions, columnManagemnetSelector } from "@app/screens/directory-configuration/pages/column-management/redux";

const SearchTextField = styled(TextField)(({theme}) => `
  input: {
    padding: 12px 30px 12px 0;
    color: ${theme.palette.primary.main};
    ::placeholder: ${theme.palette.primary.main};
  }
`);

const DirectoryNavBar = () => {
  const dispatch = useDispatch();

  const title = useSelector(directorySelector.getNavTitle());
  const action = useSelector(directorySelector.getNavAction());
  const search = useSelector(directorySelector.getNavSearch());
  const showToggle = useSelector(directorySelector.getShowNavToggle(), shallowEqual);
  const status = useSelector(directorySelector.getStatus, shallowEqual);
  const totalCount = useSelector(directorySelector.totalRecords, shallowEqual);
  const currentPage = useSelector(directorySelector.getCurrentPage, shallowEqual);
  const tabList = useSelector(columnManagemnetSelector.getTabList(), shallowEqual);

  const onActionHandler = useCallback(() => {
    dispatch(directoryNavActionHandlerCreator({ show: true, type: action.type }))
  }, [action])

  const onSearchHandler = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(directoryNavSearchQueryCreator(event.target.value));
  }, [])

  const onToggleHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(directoryNavToggleCreator(event.target.checked))
  },[])

  const onTabChangeHandler = useCallback((_: any, val: any) => {
    dispatch(columnManagementActions.updateCurrentTabId(val.value));
  }, [])

  const renderDivisionDropdownInput = useCallback(
    params => <TextField {...params} placeholder="Select" required />,
    [],
  );
  return (
    <Grid container justifyContent={'space-between'} sx={{ minHeight: 110 }}>
      <Grid item xs={8}>
        <Box sx={{
          mb: 4,
          mt: 1,
        }}>
          <Typography
            variant="title"
            color={'primary.main'}

          >
            {title} {currentPage !== directoryPageLists.columnMapping ? `( ${totalCount} )` : null}
          </Typography>
        </Box>
        {search ? <SearchTextField
          variant="outlined"
          placeholder={search.placeholder}
          type='search'
          fullWidth
          onChange={onSearchHandler}
          value={search.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        /> : null}
        {
          currentPage === directoryPageLists.columnMapping ? (
            <Box sx={{ maxWidth: 250, width: '100%', m: 4, mb: 1 }}>
              <InputLabel variant='standard'></InputLabel>
              <Autocomplete
                options={tabList}
                freeSolo
                renderInput={renderDivisionDropdownInput}
                onChange={onTabChangeHandler}
              />
            </Box>
          ) : null
        }
      </Grid>
      <Grid item display={'flex'} direction='column' justifyContent={'space-between'}>
        <div>
          {action ? (
            <Button variant="contained" onClick={onActionHandler}>{action.title}</Button>
          ) : null}
        </div>
        {showToggle ? (
          <Stack direction="row" spacing={1} alignItems="center" alignSelf={'flex-end'}>
            <Typography variant="switch">Inactive</Typography>
            <Switch sx={{
              "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked": {
                transform: 'translateX(16px)',
              },
              "& .MuiButtonBase-root.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                opacity: 1
              }
            }} defaultChecked inputProps={{ 'aria-label': 'ant design' }} checked={status === 1} onChange={onToggleHandler} />
            <Typography variant="switch">Active</Typography>
          </Stack>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default DirectoryNavBar;
