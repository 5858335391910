import { directoryConfigHelper } from "@app/screens/directory-configuration/helper";
import { directorySelector } from "@app/screens/directory-configuration/redux";
import { createSelector } from "@reduxjs/toolkit";

const columns = (state: any) => state.columnManagementState.columns;
const tabList = (state: any) => state.columnManagementState.tabList;
const columnMappingFields = (state: any) =>
  state.columnManagementState.columnMappingFields;
const currentTabId = (state: any) => state.columnManagementState.currentTabId;

// const filteredColumns: any = createSelector(
//   columns,
//   directorySelector.getStatus,
//   directoryConfigHelper.filterHandler
// );

// const getColumns = createSelector(
//   filteredColumns,
//   directorySelector.searchQuery,
//   directoryConfigHelper.searchHandler
// );
const getColumns = createSelector([columns], (_columns) => _columns);

const getTabList = createSelector([tabList], (tab) => tab);

const getCurrentTabId = createSelector([currentTabId], (_currentTabId) => _currentTabId);

const getColumnMappingFields = createSelector(
  [columnMappingFields],
  (mappingField) => mappingField
);

export const columnManagemnetSelector = {
  getColumns: () => getColumns,
  getTabList: () => getTabList,
  getColumnMappingFields: () => getColumnMappingFields,
  getCurrentTabId,
};
