import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import {Button, Stack} from '@mui/material';
import {useCallback, useEffect} from 'react';
import {configurationManagementStateActions} from '@app/screens/configuration-management/components/redux';
import {trackEvent} from '@app/utils/analytics';
import {
  EVENT_NAME_ACTIVE_BUTTON,
  EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER,
} from '@app/utils/analytics/constants';
import {ConfigurationManagementSelector} from '@app/screens/configuration-management/components/redux/seletors';
import {fetchExpenseTypeSettingGridCreator} from '@app/screens/configuration-management/components/redux/slice';
import {AntSwitch} from '@app/components/widgets/AntSwitch/AntSwitch';
import {appSelector} from '@app/store/selectors';
import {navbarCss} from '@app/components/widgets/navbar/navbarCss';
import {filterSelector, filterStateActions} from '@app/components/right-sidebar/redux';
import { fetchExportFileCreator } from '@app/screens/master-data-management/pages/expense-da-master/redux/slice';
import { ExportButtons } from '@app/components/widgets/ExportButton/export-button';
import '@app/index.css';

export const ConfigurationManagementNavBar = () => {
  const dispatch = useDispatch();

  const isActiveState = useSelector(
    ConfigurationManagementSelector.getIsActiveState(),
  );

  const request = useSelector(
    filterSelector.getFilterOptions()
  );
  const {isWriteAccess = false} = useSelector(appSelector.getUserInfo());

  const manageAddNewPopup = useCallback(() => {
    trackEvent(EVENT_NAME_ADD_NEW_EXPENSE_DA_MASTER, {});
    dispatch(
      configurationManagementStateActions.setaddExpenseConfigManagementPopupVisible(
        true,
      ),
    );
  }, []);
  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading(),
  );
  useEffect(() => {
    dispatch(filterStateActions.setFilterOptions({
      ...request,
      isActive:isActiveState
    }))
    dispatch(fetchExpenseTypeSettingGridCreator(null));
  }, [isActiveState])
  const expenseSettingActiveInactive = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      trackEvent(EVENT_NAME_ACTIVE_BUTTON, {});

      dispatch(
        configurationManagementStateActions.setIsActiveState(
          event.target.checked,
        ),
      );
    },
    [],
  );
  useEffect(() => {
    return()=>{
      dispatch(filterStateActions.setFilterOptions({
        isActive: true,
        searchText: "",
        pageNumber: 0,
        pageLimit:100,
        divisionIds: [],
        designationIds: [],
        hqIds: []
      }))
    }
  },[])
  const exportButtonSettings=(e:any)=>{
    dispatch(fetchExportFileCreator(e.target.name))
  }

  return (
    <div style={navbarCss.containercss}>
      <div style={navbarCss.flexSpaceBetween}>
        <Typography
          variant="h6"
          noWrap
          component="div"
          color={'#322b7c'}
          fontWeight={'800'}
          display={'flex'}>
          {navbarComponentHeading}
        </Typography>

        <div style={navbarCss.flexSpaceBetween}>
        <ExportButtons onClick={exportButtonSettings} isWriteAccess = {isWriteAccess}  name={"expensetypesettings"}/>
          <Button
            data-testid="add-config-new"
            variant="contained"
            disabled={!isWriteAccess}
            sx={navbarCss.button}
            onClick={manageAddNewPopup}>
            Add New
          </Button>
        </div>
      </div>
      <div style={navbarCss.flexSpaceBetween}>
        <div style={navbarCss.margintopcss}></div>

        <div style={navbarCss.margintopcss}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            paddingLeft={'7px'}>
            <Typography
              color={'#322b7c'}
              fontWeight={'800'}
              fontFamily={'Poppins'}
              fontSize={'normal'}>
              In-Active
            </Typography>
            <AntSwitch
              checked={isActiveState}
              onChange={expenseSettingActiveInactive}
              inputProps={{'aria-label': 'ant design'}}
            />
            <Typography
              color={'#322b7c'}
              fontWeight={'800'}
              fontFamily={'Poppins'}
              fontSize={'normal'}>
              Active
            </Typography>
          </Stack>
        </div>
      </div>
    </div>
  );
};
