import {
    applyFilterWatcher,
    applySetDefaultFilterWatcher,
} from '@app/components/right-sidebar/redux/filterHandlerSaga';
import { fetchRoutesDataWatcher } from '@app/router/redux/routeHandlerSaga';
import {
    accessGroupMenuItemsWatcher,
    addAccessGroupWatcher,
    disableAccessGroupWatcher,
    editAccessGroupWatcher,
    fetchAccessGroupMasterWatcher,
    fetchAccessGroupsAutoSuggestDataWatcher,
} from "@app/screens/access-management/pages/access-group-master/redux/saga";
import {
    fetchInventoryCnfReturnWatcher,
    fetchCNFStatesAndDivisionsWatcher,
    fetchCNFHeadquartersWatcher,
    fetchCnfAutoSuggestionWatcher,
    fetchFilteredDataWatcher,
    fetchCnfReturnItemsFilterWatcher,
    fetchApprovedItemsWatcher,
    fetchRejectItemsWatcher,
} from '@app/screens/inventory-management/pages/cnf-return-items/redux/saga';
import {
    fetchInventoryIntransistChallansWatcher,
    fetchAutoSuggestionWatcher,
    fetchInTransitFilteredDataWatcher,
    postIntransitChallanOpenCloseWatcher,
    enableDisableIntransitChallansWatcher,
    fetchVoucherNumberDataWatcher,
    fetchRecievedChallansDataWatcher,
} from '@app/screens/inventory-management/pages/intransit-challan/redux/saga';
import {
    editAdminUserWatcher,
    fetchAccessGroupDropDownWatcher,
    fetchAdminUsersDataWatcher,
    fetchDivisionDropdownWatcher,
    fetchStateDropdownWatcher,
    addAdminUserWatcher,
    disableAdminUserWatcher,
    fetchAdminUsersAutoSuggestDataWatcher,
    fetchAdminAddUsersAutoSuggestDataWatcher,
} from '@app/screens/access-management/pages/user-management/redux/saga';

import {
    fetchPlanAndMeetStatusListWatcher,
    updateMTPStatusWatcher,
    BulkGSPUpdateWatcher,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux';

import {
    fetchStateDataWatcher,
    fetchDivisionDataWatcher,
    fetchManagerDataWatcher,
    fetchStatusDataWatcher,
    fetchStaffDataWatcher,
    fetchExpenseApprovalsWatcher,
    fetchDailyExpenseApprovalsWatcher,
    fetchMiscExpenseApprovalsWatcher,
    fetchConfirmExpenseApprovalsWatcher,
    fetchRejectExpenseApprovalsWatcher,
    fetchDailyExpenseApprovalsViewBillWatcher,
    uploadMiscExpenseDataWatcher,
    fetchMiscExpenseApprovalsViewBillWatcher,
    fetchDailyExpenseApprovalsDcrReportWatcher,
    fetchDailyExpenseDcrMapUrlDataWatcher,
    fetchMiscExpenseFileDeleteDataWatcher,
    fetchDailyExpensePopupReadOnlyDataWatcher,
    fetchDailyExpensePopupTADADataWatcher,
    fetchDailyExpensePopupTADASaveDataWatcher,
    fetchMiscSaveWatcher,
    deductDaAmountWatcher,
    approveExpenseStatusWatcher,
} from "@app/screens/module-specific-utilities/pages/approve-expenses/redux/saga";
import {
    fetchExpenseTypeDataWatcher,
    fetchExpenseCategoryWatcher,
    fetchactiveInactiveExpenseTypeWatcher,
    fetchExpenseTypeAutoSuggestDataWatcher,
    createExpenseTyperWatcher,
    editExpenseTypeWatcher,
    fetchExpenseTypeOptionsWatcher,
    fetchAutoSuggestHqDataWatcher,
} from "@app/screens/component-management/components/redux/saga";
import {
    fetchExpenseTAMasterGridDataWatcher,
    fetchExpenseTAMasterInAutoSuggestDataWatcher,
    activeInactiveExpenseTAMasterWatcher,
    addAddExpenseTAMasterWatcher,
    editExpenseTAMastereWatcher,
    AddExpenseValidationTAMasterWatcher,
} from "@app/screens/master-data-management/pages/expense-ta-master/redux/saga";
import {
    fetchExpenseDAMasterGridDataWatcher,
    addExpenseDAMasterWatcher,
    fetchDesignationsDropdownWatcher,
    fetchHQDropdownWatcher,
    activeInactiveExpenseDAMasterWatcher,
    editExpenseDAMastereWatcher,
    fetchExpenseDAMasterInAutoSuggestDataWatcher,
    fetchExpenseDAMasterDesignationByDivisionWatcher,
    fetchHQTypeDropdownWatcher,
    fetchHqByEdividionDesginationDAMasterWatcher,
    AddExpenseValidationDAMasterWatcher,
    fetchExportFileCreatorWatcher,
} from "@app/screens/master-data-management/pages/expense-da-master/redux/saga";

import { all, call, spawn } from "redux-saga/effects";
import {
    fetchExpenseTypeDropdownConfigDataWatcher,
    fetchExpenseTypeSettingGridDataWatcher,
    fetchactiveInactiveExpenseTypeSettingWatcher,
    createExpenseTypeSettingrWatcher,
    editExpenseTypeSettingeWatcher,
    ExpenseDateWatcher,
    saveExpenseTypeDateGridWatcher,
    fetchHqByEdividionDesginationxpenseTypeSettingWatcher,
    createExpenseTypeSettingValidationWatcher,
} from "@app/screens/configuration-management/components/redux/saga";
import {
    fetchCannotAccessModalUsersWatcher,
    fetchNotificationsWatcher,
    fetchUserInfoWatcher,
    markNotificationReadWatcher,
    fetchUserAppSettingsWatcher,
} from '@app/store/sagas/appSaga';
import {
    fetchFilterOptionsWatcher,
    fetchHqsWatcher,
    fetchMTPRecommendationsWatcher,
    filterBasedDownloadExcelWatcher,
    fetchPreferredFiltersWatcher,
    fetchMTPUnlockReasonsWatcher,
    enableDCRWatcher,
    fetchPlanAndMeetHierarchyWatcher,
    HierarchyBasedDownloadExcelWatcher,
    webAccessUnlockWatcher,
    getWebAccessStatusWatcher,
} from '@app/screens/module-specific-utilities/pages/plan-and-meet/redux/saga';
import {
    fetchDcrUnlockRequestListWatcher,
    fetchDCRUnlockReasonsWatcher,
    approveDCRUnlockRequestWatcher,
    fetchDCRHqsWatcher,
    dcrDownloadExcelWatcher,
} from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux';
import {
    fetchPartiesBySpidWatcher,
    fetchPartyDetailBySpidWatcher,
    fetchCloningPeriodWatcher,
    transferPositionUpdateWatcher,
    fetchPositionCloningListWatcher,
    clonePositionUpdateWatcher,
    fetchPositionTransferListWatcher,
    validatePartyTransferWatcher,
} from '@app/screens/module-specific-utilities/pages/position-management/redux';
import {
    fetchApprovalWorkflowListWatcher,
    fetchMasterConfigDataWatcher,
    createApprovalResponseWatcher,
} from '@app/screens/configuration-management/pages/approval-workflow/redux';
import { updateMinApproverConfigWatcher } from '@app/screens/configuration-management/pages/approval-workflow/redux/saga';
import { modifyDCRRequestWatcher } from '@app/screens/module-specific-utilities/pages/dcr-unlock-request/redux/saga';
import {
    fetchInventoryConfiguratorCustomerTypeWatcher,
    fetchInventoryConfiguratorDivisionWatcher,
    fetchInventoryConfiguratorDoctorQualificationWatcher,
    fetchInventoryConfiguratorDoctorSpecialityWatcher,
    fetchInventoryConfiguratorRegionHeirarcyWatcher,
    fetchInventoryConfiguratorSpecCategoryWatcher,
    fetchInventoryConfiguratorClassCodeWatcher,
    fetchInventoryConfiguratorRolesWatcher,
    fetchInventoryConfiguratorItemCategoryWatcher,
    fetchInventoryConfiguratorItemCodeWatcher,
    fetchInventoryConfiguratorSetValueWatcher,
} from '@app/screens/inventory-management/pages/inventoryConfigurator/redux/saga';
import {
    fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher,
    fetchExpectedUtilizatonDaysWatcher,
    fetchNewExpectedUtilizatonDaysWatcher,
} from '@app/screens/inventory-management/pages/Expected Utilizaton Date/redux/saga';
import {
    fetchItemCategoryWatcher,
    fetchInventoryRolesWatcher,
    fetchInventoryDeleteRolesWatcher,
    fetchInventorySaveRolesWatcher,
    fetchInventoryDivisionWatcher,
    fetchInventoryRolesPage2Watcher,
    fetchInventorySaveRolesPage2Watcher,
    fetchInventoryDeleteRolesPage2Watcher,
    fetchInventoryPartyTypeWatcher,
} from '@app/screens/inventory-management/pages/inventoryRules/redux/saga';
import { fetchGenerateReportDataWatcher } from "@app/screens/module-specific-utilities/pages/generateReport/redux/saga";
import {
    fetchGetExpenseStatusActionsWatcher,
    fetchStatusManagerAutoSuggestStaffWatcher,
    saveExpenseStatusActionsWatcher,
} from "@app/screens/module-specific-utilities/pages/status-manager/redux/saga";
import {
    fetchDesignationByDivisionWatcher,
    fetchEmployeebydivisionWatcher,
    fetchHqByDivisionsandDesignationsWatcher,
    fetchPoolByDivisionsandDesignationsWatcher,
    fetchCategoryWatcher,
    fetchStatusWatcher,
    fetchDivisionWatcher,
    fetchBillDownloadWatcher,
    fetchViewHistoryWatcher,
    reviewSaveWatcher,
    fetchEmployeebydivisionforDropdownWatcher,
} from "@app/screens/module-specific-utilities/pages/bill-library/redux/saga";
import {
    fetchExpenseParameterDataWatcher,
    fetchDownloadWatcher,
    fileUploadrDataWatcher,
} from "@app/screens/module-specific-utilities/pages/expense/redux/saga";
import {
    directoryLoadingHandlerWatcher,
    directoryNavActionHandlerWatcher,
    directoryNavSearchQueryWatcher,
    directoryNavToggleWatcher,
    directoryUpdateCurrentPageWatcher,
    updateDirectoryNavWatcher,
} from "@app/screens/directory-configuration/redux";
import {
    fetchDivisionsWatcher,
    fetchPartyGroupsWatcher,
    updateTabsWatcher,
} from "@app/screens/directory-configuration/pages/tab-management/redux";
import {
    fetchHoUserCategoryWatcher,
    fetchHoUserDepartmentWatcher,
    fetchHoUserDivisionWatcher,
    fetchHoUserListWatcher,
    fetchHoUserRegionWatcher,
    fetchHoUserStateWatcher,
} from "@app/screens/directory-configuration/pages/ho-user/redux";
import {
    fetchMappingFieldsWatcher,
    fetchTabListWatcher,
    updateColumnsWatcher,
} from "@app/screens/directory-configuration/pages/column-management/redux";
import {
    getSocialMediaCategoryWatcher,
    updateSocialMediaListWatcher,
} from "@app/screens/directory-configuration/pages/social media/redux";
import { fetchDepartmentListWatcher } from "@app/screens/directory-configuration/pages/department-management/redux";
import { fetchHoContactCategoryWatcher } from "@app/screens/directory-configuration/pages/ho-contact-category/redux";
import { fetchSocialMediaCategoryListWatcher } from "@app/screens/directory-configuration/pages/social-media-category/redux/saga";
import {
    moduleSpecificUtilitiesLoadingHandlerWatcher,
    moduleSpecificUtilitiesNavActionHandlerWatcher,
    moduleSpecificUtilitiesNavSearchQueryWatcher,
    moduleSpecificUtilitiesNavToggleWatcher,
    moduleSpecificUtilitiesUpdateCurrentPageWatcher,
    updateModuleSpecificUtilitiesNavWatcher
} from '@app/screens/module-specific-utilities/redux';
import { fetchMetricCategoryListWatcher } from '@app/screens/module-specific-utilities/pages/metric-category/redux';
import { fetchMetricCategoryDropdownWatcher } from '@app/screens/module-specific-utilities/pages/metric-category/redux/saga';
import { fetchDownloadExcelURLWatcher, fetchPartyMastersWatcher } from '@app/screens/module-specific-utilities/pages/digital-twin-party-master/redux';
import { fetchMetricPropertiesListWatcher } from '@app/screens/module-specific-utilities/pages/metric-properties/redux';
import {unmappedActivityListWatcher,mappedActivityListWatcher,saveActivityMappingWatcher,fetchDivisionMappingWatcher,fetchActivitiesWatcher, saveActivitiesWatcher} from '@app/screens/configuration-management/pages/activity-mapping/redux'


export function* rootSaga() {
    /**
     *
     *  Register watchers
     *
     */
    const sagas: any = [
        fetchRoutesDataWatcher,
        fetchUserInfoWatcher,
        fetchAdminUsersDataWatcher,
        fetchAccessGroupMasterWatcher,
        fetchAccessGroupDropDownWatcher,
        fetchDivisionDropdownWatcher,
        fetchStateDropdownWatcher,
        editAdminUserWatcher,
        addAdminUserWatcher,
        disableAdminUserWatcher,
        fetchAdminUsersAutoSuggestDataWatcher,
        fetchAdminAddUsersAutoSuggestDataWatcher,
        fetchCannotAccessModalUsersWatcher,
        applyFilterWatcher,
        applySetDefaultFilterWatcher,
        fetchAccessGroupsAutoSuggestDataWatcher,
        editAccessGroupWatcher,
        disableAccessGroupWatcher,
        addAccessGroupWatcher,
        accessGroupMenuItemsWatcher,
        fetchPlanAndMeetStatusListWatcher,
        updateMTPStatusWatcher,
        fetchNotificationsWatcher,
        fetchMTPRecommendationsWatcher,
        fetchFilterOptionsWatcher,
        fetchHqsWatcher,
        filterBasedDownloadExcelWatcher,
        fetchPreferredFiltersWatcher,
        fetchMTPUnlockReasonsWatcher,
        markNotificationReadWatcher,
        enableDCRWatcher,
        fetchDcrUnlockRequestListWatcher,
        fetchDCRUnlockReasonsWatcher,
        approveDCRUnlockRequestWatcher,
        fetchUserAppSettingsWatcher,
        fetchPlanAndMeetHierarchyWatcher,
        HierarchyBasedDownloadExcelWatcher,
        fetchDCRHqsWatcher,
        dcrDownloadExcelWatcher,
        fetchPartiesBySpidWatcher,
        fetchPartyDetailBySpidWatcher,
        fetchCloningPeriodWatcher,
        transferPositionUpdateWatcher,
        fetchPositionCloningListWatcher,
        clonePositionUpdateWatcher,
        fetchApprovalWorkflowListWatcher,
        fetchMasterConfigDataWatcher,
        createApprovalResponseWatcher,
        BulkGSPUpdateWatcher,
        updateMinApproverConfigWatcher,
        fetchInventoryCnfReturnWatcher,
        fetchInventoryIntransistChallansWatcher,
        fetchAutoSuggestionWatcher,
        fetchInTransitFilteredDataWatcher,
        postIntransitChallanOpenCloseWatcher,
        enableDisableIntransitChallansWatcher,
        fetchVoucherNumberDataWatcher,
        fetchCNFStatesAndDivisionsWatcher,
        fetchCNFHeadquartersWatcher,
        fetchCnfAutoSuggestionWatcher,
        fetchFilteredDataWatcher,
        fetchCnfReturnItemsFilterWatcher,
        fetchApprovedItemsWatcher,
        fetchRecievedChallansDataWatcher,
        fetchRejectItemsWatcher,
        fetchInventoryConfiguratorDivisionWatcher,
        fetchInventoryConfiguratorCustomerTypeWatcher,
        fetchInventoryConfiguratorRegionHeirarcyWatcher,
        fetchInventoryConfiguratorDoctorSpecialityWatcher,
        fetchInventoryConfiguratorDoctorQualificationWatcher,
        fetchInventoryConfiguratorSpecCategoryWatcher,
        fetchInventoryConfiguratorClassCodeWatcher,
        fetchInventoryConfiguratorRolesWatcher,
        fetchInventoryConfiguratorItemCategoryWatcher,
        fetchInventoryConfiguratorItemCodeWatcher,
        fetchInventoryConfiguratorSetValueWatcher,
        modifyDCRRequestWatcher,
        fetchExpenseTypeDataWatcher,
        fetchExpenseCategoryWatcher,
        fetchExpenseTypeDropdownConfigDataWatcher,
        fetchDesignationsDropdownWatcher,
        fetchHQDropdownWatcher,
        fetchExpenseTAMasterGridDataWatcher,
        fetchExpenseTAMasterInAutoSuggestDataWatcher,
        activeInactiveExpenseTAMasterWatcher,
        addAddExpenseTAMasterWatcher,
        fetchactiveInactiveExpenseTypeWatcher,
        fetchExpenseTypeAutoSuggestDataWatcher,
        createExpenseTyperWatcher,
        editExpenseTypeWatcher,
        editExpenseTAMastereWatcher,
        fetchExpenseTypeSettingGridDataWatcher,
        fetchactiveInactiveExpenseTypeSettingWatcher,
        createExpenseTypeSettingrWatcher,
        editExpenseTypeSettingeWatcher,
        fetchExpenseDAMasterGridDataWatcher,
        activeInactiveExpenseDAMasterWatcher,
        addExpenseDAMasterWatcher,
        editExpenseDAMastereWatcher,
        fetchDivisionWatcher,
        fetchExpenseDAMasterInAutoSuggestDataWatcher,
        fetchGenerateReportDataWatcher,
        fetchStatusManagerAutoSuggestStaffWatcher,
        fetchGetExpenseStatusActionsWatcher,
        saveExpenseStatusActionsWatcher,
        fetchStateDataWatcher,
        fetchDivisionDataWatcher,
        fetchManagerDataWatcher,
        fetchStaffDataWatcher,
        fetchExpenseApprovalsWatcher,
        fetchStatusDataWatcher,
        fetchDailyExpenseApprovalsWatcher,
        fetchMiscExpenseApprovalsWatcher,
        fetchConfirmExpenseApprovalsWatcher,
        fetchRejectExpenseApprovalsWatcher,
        fetchDesignationByDivisionWatcher,
        fetchEmployeebydivisionWatcher,
        fetchHqByDivisionsandDesignationsWatcher,
        fetchPoolByDivisionsandDesignationsWatcher,
        fetchExpenseParameterDataWatcher,
        fetchDownloadWatcher,
        fetchCategoryWatcher,
        fetchStatusWatcher,
        ExpenseDateWatcher,
        fileUploadrDataWatcher,
        saveExpenseTypeDateGridWatcher,
        fetchExpenseTypeOptionsWatcher,
        fetchHQTypeDropdownWatcher,
        fetchExpenseDAMasterDesignationByDivisionWatcher,
        fetchBillDownloadWatcher,
        fetchViewHistoryWatcher,
        fetchHqByEdividionDesginationxpenseTypeSettingWatcher,
        fetchDailyExpenseApprovalsViewBillWatcher,
        uploadMiscExpenseDataWatcher,
        fetchAutoSuggestHqDataWatcher,
        fetchMiscExpenseApprovalsViewBillWatcher,
        fetchHqByEdividionDesginationDAMasterWatcher,
        fetchDailyExpenseApprovalsDcrReportWatcher,
        fetchDailyExpenseDcrMapUrlDataWatcher,
        AddExpenseValidationDAMasterWatcher,
        AddExpenseValidationTAMasterWatcher,
        createExpenseTypeSettingValidationWatcher,
        reviewSaveWatcher,
        fetchMiscExpenseFileDeleteDataWatcher,
        webAccessUnlockWatcher,
        getWebAccessStatusWatcher,
        fetchPositionTransferListWatcher,
        validatePartyTransferWatcher,
        fetchDownloadExcelURLWatcher,
        validatePartyTransferWatcher,
        fetchExpectedUtilizatonDateSkuAutoSuggestionWatcher,
        fetchExpectedUtilizatonDaysWatcher,
        fetchNewExpectedUtilizatonDaysWatcher,
        fetchItemCategoryWatcher,
        fetchInventoryRolesWatcher,
        fetchInventoryDeleteRolesWatcher,
        fetchInventorySaveRolesWatcher,
        fetchInventoryDivisionWatcher,
        fetchInventoryRolesPage2Watcher,
        fetchInventorySaveRolesPage2Watcher,
        fetchInventoryDeleteRolesPage2Watcher,
        fetchInventoryPartyTypeWatcher,
        validatePartyTransferWatcher,
        fetchEmployeebydivisionforDropdownWatcher,
        fetchExportFileCreatorWatcher,
        fetchDailyExpensePopupReadOnlyDataWatcher,
        fetchDailyExpensePopupTADADataWatcher,
        fetchDailyExpensePopupTADASaveDataWatcher,
        fetchMiscSaveWatcher,
        deductDaAmountWatcher,
        approveExpenseStatusWatcher,

        updateDirectoryNavWatcher,
        updateTabsWatcher,
        directoryNavActionHandlerWatcher,
        fetchDivisionsWatcher,
        fetchPartyGroupsWatcher,
        directoryLoadingHandlerWatcher,
        updateColumnsWatcher,
        fetchTabListWatcher,
        fetchMappingFieldsWatcher,
        fetchHoContactCategoryWatcher,
        directoryNavSearchQueryWatcher,
        fetchHoUserCategoryWatcher,
        fetchHoUserDepartmentWatcher,
        fetchHoUserDivisionWatcher,
        fetchHoUserStateWatcher,
        fetchHoUserRegionWatcher,
        fetchHoUserListWatcher,
        fetchDepartmentListWatcher,
        directoryNavToggleWatcher,
        updateSocialMediaListWatcher,
        getSocialMediaCategoryWatcher,
        fetchSocialMediaCategoryListWatcher,
        directoryUpdateCurrentPageWatcher,
        fetchPlanAndMeetStatusListWatcher,
        moduleSpecificUtilitiesLoadingHandlerWatcher,
        moduleSpecificUtilitiesNavActionHandlerWatcher,
        moduleSpecificUtilitiesNavSearchQueryWatcher,
        moduleSpecificUtilitiesNavToggleWatcher,
        moduleSpecificUtilitiesUpdateCurrentPageWatcher,
        updateModuleSpecificUtilitiesNavWatcher,
        fetchMetricCategoryListWatcher,
        fetchMetricCategoryDropdownWatcher,
        fetchPartyMastersWatcher,
        fetchMetricPropertiesListWatcher,
    unmappedActivityListWatcher,
    mappedActivityListWatcher,
    saveActivityMappingWatcher,
    fetchActivitiesWatcher,
    fetchDivisionMappingWatcher,
    approveExpenseStatusWatcher,
    unmappedActivityListWatcher,
    mappedActivityListWatcher,
    saveActivityMappingWatcher,
    fetchActivitiesWatcher,
    fetchDivisionMappingWatcher,
    saveActivitiesWatcher

  ];

    /**
     * keep everything (e.g., child tasks) alive
     *
     **/
    yield all(
        sagas.map((saga: any) =>
            spawn(function* () {
                while (true) {
                    try {
                        yield call(saga);
                        break;
                    } catch (e) {
                    }
                }
            })
        )
    );
}
