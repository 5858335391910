import {useEffect} from 'react';
import {routeSelector} from '@app/router/redux';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
export const ConfigurationManagement = () => {
  const appRoutesConfigurationManagement = useSelector(routeSelector.getAppRoutes());
  const navigate = useNavigate();
  useEffect(() => {
    let selectedRouteConfigurationManagement: any = {};
    appRoutesConfigurationManagement.map((route: any) => {
      if (route.webPath === '/configuration-management') {
        selectedRouteConfigurationManagement = route;
      }
      return null;
    });
    if (
      selectedRouteConfigurationManagement?.firstLevel?.length > 0
    ) {
      const firstRoute: any = selectedRouteConfigurationManagement.firstLevel[0];
      navigate(firstRoute.webPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appRoutesConfigurationManagement]);

  return <div></div>;
};
