import {createSelector} from '@reduxjs/toolkit';

const getApprovalWorkflowState = (state: any) => state.approvalWorkflowMaster;

const getApprovalWorkflowListSelector = createSelector(
  [getApprovalWorkflowState],
  data => data?.approvalWorkflowList,
);

const getCloneApprovalWorkflowListSelector = createSelector(
  [getApprovalWorkflowState],
  data => data?.cloneApprovalWorkflowList,
);

const getTotalRowsInGridSelector = createSelector(
  [getApprovalWorkflowState],
  data => data?.totalRows,
);

const getApprovalWorkflowSearchTextSelector = createSelector(
  [getApprovalWorkflowState],
  data => data?.searchText,
);

const getApprovalWorkflowGridOptionsSelector = createSelector(
  [getApprovalWorkflowState],
  data => data?.gridOptions,
);

const getCreateWorkflowScreenSelector = createSelector(
  [getApprovalWorkflowState],
  data => data?.createWorkflowScreen,
);

const getMasterConfigData = createSelector(
  [getApprovalWorkflowState], 
  data => data?.masterConfigData,
)

const getEditableFieldData = createSelector(
  [getApprovalWorkflowState],
  data => data?.editableField
)

const getCreateApporvalResponse = createSelector(
  [getApprovalWorkflowState], 
  data => data?.createApprovalResponse,
)

const getMinApproverResponse = createSelector(
  [getApprovalWorkflowState], 
  data => data?.minApproverResponse,
)

export const approvalWorkflowSelector = {
  getApprovalWorkflowSearchText: () => getApprovalWorkflowSearchTextSelector,
  getApprovalWorkflowGridOptions: () => getApprovalWorkflowGridOptionsSelector,
  getCloneApprovalWorkflowList: () => getCloneApprovalWorkflowListSelector,
  getTotalRowsInGrid: () => getTotalRowsInGridSelector,
  getCreateWorkflowScreen: () => getCreateWorkflowScreenSelector,
  getApprovalWorkflowList: () => getApprovalWorkflowListSelector,
  getMasterConfigData: () => getMasterConfigData,
  getEditableFieldData: () => getEditableFieldData,
  getCreateApporvalResponse: () => getCreateApporvalResponse,
  getMinApproverResponse: () => getMinApproverResponse,
};
