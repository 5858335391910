import {routeStateActions} from '@app/router/redux';
import {navbarComponentName} from '@app/router/redux/routeHandler';
import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {BillLibraryContent} from './billLibrarycontent';
import {billLibraryStateActions} from './redux';
import {useStyles} from '@app/screens/Styles/style';

export const BillLibrary = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(
      routeStateActions.setNavbarComponentName(
        navbarComponentName.commonheader,
      ),
    );
    dispatch(
      routeStateActions.setNavbarComponentHeading(
        <div className={classes.navbarComponentHeadingEmpty}>Bill Library</div>,
      ),
    );
    return () => {
      dispatch(routeStateActions.setNavbarComponentName(null));
      dispatch(billLibraryStateActions.setGridVeiw(false));
      dispatch(billLibraryStateActions.setEmployee([]));
      dispatch(billLibraryStateActions.setViewHistory([]));
    };
  }, [classes.navbarComponentHeadingEmpty, dispatch]);
  return (
    <div>
      <BillLibraryContent />
    </div>
  );
};
