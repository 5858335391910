import { createAction, createSlice } from "@reduxjs/toolkit";

export const actions = {
  uploadExcel: "uploadExcel",
  addMetricCategory: 'addMetricCategory',
  addMetricProperties: 'addMetricProperties',
};

export const moduleSpecificUtilitiesPageLists = {
  digitalTwinPartyMaster: "digitalTwinPartyMaster",
};

interface initialStateProps {
  nav: Record<string, any>;
  pages: Record<string, Record<string, any>>;
  modal: {};
  loading: null | number | boolean;
  currentPage: string | null;
}

const initialState: initialStateProps = {
  nav: {
    title: null,
    action: {
      title: null,
      type: null,
    },
    search: {
      placeholder: "Search here",
      query: "",
    },
    status: {
      show: false,
      value: true,
    },
    tabs: {
      show: false,
      current: null,
    }
  },
  pages: {
    tabManagement: {
      count: 0,
      pageIndex: 1,
    },
  },
  modal: {
    show: null,
    type: null,
    name: null,
  },
  loading: null,
  currentPage: null,
};

export const updateModuleSpecificUtilitiesNavDataCreator = createAction<any>(
  "MODULE_SPECIFIC_UTILITIES/UPDATE_NAV_DATA"
);
export const updateModuleSpecificUtilitiesNavDataCreatorTypeName =
  updateModuleSpecificUtilitiesNavDataCreator(null).type;

export const moduleSpecificUtilitiesNavActionHandlerCreator = createAction<any>(
  "MODULE_SPECIFIC_UTILITIES/UPDATE_NAV_ACTION_HANDLR"
);

export const moduleSpecificUtilitiesNavActionHandlerCreatorTypeName =
  moduleSpecificUtilitiesNavActionHandlerCreator(null).type;

export const moduleSpecificUtilitiesNavToggleCreator = createAction<any>(
  "MODULE_SPECIFIC_UTILITIES/UPDATE_NAV_TOGGLE"
);

export const moduleSpecificUtilitiesNavToggleCreatorTypeName =
  moduleSpecificUtilitiesNavToggleCreator(null).type;

export const moduleSpecificUtilitiesLoadingHandlerCreator = createAction<any>(
  "MODULE_SPECIFIC_UTILITIES/LOADING_HANDLER"
);

export const moduleSpecificUtilitiesLoadingHandlerCreatorTypeName =
  moduleSpecificUtilitiesLoadingHandlerCreator(null).type;

export const moduleSpecificUtilitiesNavSearchQueryCreator = createAction<any>(
  "MODULE_SPECIFIC_UTILITIES/directoryNavSearchQueryCreator"
);

export const moduleSpecificUtilitiesNavSearchQueryCreatorTypeName =
  moduleSpecificUtilitiesNavSearchQueryCreator(null).type;

export const moduleSpecificUtilitiesUpdateCurrentPageCreator = createAction<any>(
  "MODULE_SPECIFIC_UTILITIES/moduleSpecificUtilitiesUpdateCurrentPageCreator"
);

export const moduleSpecificUtilitiesUpdateCurrentPageCreatorTypeName =
  moduleSpecificUtilitiesUpdateCurrentPageCreator(null).type;

const moduleSpecificUtilitiesSlice = createSlice({
  name: "MUDULE_SPECIFIC_UTILITIES",
  initialState: initialState,
  reducers: {
    updateNavData: (state: any, action: { payload: any; }) => {
      return {
        ...state,
        nav: action.payload,
      };
    },
    updateModalHandler: (state, action) => {
      return {
        ...state,
        modal: action.payload,
      };
    },
    updateLoading: (state, action) => {
      return {
        ...state,
        loading: action.payload,
      };
    },
    updateTotalRecords: (state, action) => {
      const { listing, ...props }: { listing: string; count: number } =
        action.payload;
      return {
        ...state,
        pages: {
          ...state.pages,
          [listing]: {
            ...state.pages[listing],
            ...props,
          },
        },
      };
    },
    updateNavSearchQuery: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          search: {
            ...state.nav.search,
            query: action.payload,
          },
        },
      };
    },
    updateCurrentPage: (state, action) => {
      return {
        ...state,
        currentPage: action.payload,
      };
    },
    updateNavToggle: (state, action) => {
      return {
        ...state,
        nav: {
          ...state.nav,
          status: {
            ...state.nav.status,
            value: action.payload,
          },
        },
      };
    },
  },
});

export const moduleSpecificUtilitiesActions = moduleSpecificUtilitiesSlice.actions;
export const moduleSpecificUtilitiesReducer = moduleSpecificUtilitiesSlice.reducer;
