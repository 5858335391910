import { createSelector } from "@reduxjs/toolkit";

export const expenseStateSelector = {
    getParameter: () => {
        return createSelector(
          [(state: any) => state.expesne.parameter],
          (parameter) => parameter
        );
      },
      getDownload: () => {
        return createSelector(
          [(state: any) => state.expesne.download],
          (download) => download
        );
      },
      getUploadResponse: () => {
        return createSelector(
          [(state: any) => state.expesne.uploadResponse],
          (uploadResponse) => uploadResponse
        );
      },
}