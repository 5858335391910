import { createSelector } from "@reduxjs/toolkit";

const getMetricCategoryLists = (state: any) =>
  state.metricCategoryState.metricCategoryList;

const getMetricCategoryDropdowns = (state: any) =>
state.metricCategoryState.metricCategoryDropdown;


const getMetricCategoryList = createSelector(
  [getMetricCategoryLists],
  (_getMetricCategoryLists) => _getMetricCategoryLists
);

const getMetricCategoryDropdown = createSelector(
  [getMetricCategoryDropdowns],
  (_getMetricCategoryDropdowns) => _getMetricCategoryDropdowns
);

export const metricCategorySelector = {
  getMetricCategorylist: () => getMetricCategoryList,
  getMetricCategoryDropdown: () => getMetricCategoryDropdown,
};

