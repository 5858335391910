import {createAction, createSlice} from '@reduxjs/toolkit';

export const intrasistChallanInitialState = {
  inTransistAccessType: '',
  intransitChallanloading: false,
  voucherDataLoading: false,
  challanData: [
    {
      id: 1,
      stateName: 'Andra Pradesh',
      checked: true,
      expanded: true,
      division: [{
        id: 11,
        divisionName: '3D Mankind',
        checked: true,
      },{
        id: 12,
        divisionName: 'Discovery',
        checked: true,
      }]
    },{
      id: 2,
      stateName: 'Andra Pradesh',
      checked: true,
      expanded: false,
      division: [{
        id: 21,
        divisionName: '3D Mankind',
        checked: true,
      },{
        id: 22,
        divisionName: 'Discovery',
        checked: true,
      }]
    },{
      id: 3,
      stateName: 'Andra Pradesh',
      checked: true,
      expanded: false,
      division: [{
        id: 31,
        divisionName: '3D Mankind',
        checked: true,
      },{
        id: 32,
        divisionName: 'Discovery',
        checked: true,
      }]
    },{
      id: 4,
      stateName: 'Andra Pradesh',
      checked: true,
      expanded: false,
      division: [{
        id: 41,
        divisionName: '3D Mankind',
        checked: true,
      },{
        id: 42,
        divisionName: 'Discovery',
        checked: true,
      }]
    }
  ],
  autoSuggestionData: [],
  filteredData: [],
  intransitChallanError: '',
  selectedChallans: [],
  recievedChallans: [],
  searchType: '',
  searchvalue: '',
};

export const fetchInTransistAccessCreator = createAction<any>(
  'IN-TRANSIST-CHALLAN',
);
export const inTransitionAutoSuggestCreator =
  createAction<any>('AUTO_SUGGESTION');
export const inTransitFilteredData = createAction<any>('FILTERED_DATA');
export const postOpenCloseChallanCreator = createAction<any>('POST_OPEN_CLOSE');
export const recieveChallansCreator = createAction<any>('RECIEVE_CHALLANS');
export const enableDisableIntransitChallansCreator = createAction<any>(
  'ENABLE-DISABLE_INTRANSIT_CHALLAN',
);
export const fetchVoucherDataCreator = createAction<any>('VOUCHER_NUMBER_DATA');

export const fetchInTransistAccessName = fetchInTransistAccessCreator(
  {},
).type;
export const fetchAutoSuggestion = inTransitionAutoSuggestCreator({}).type;
export const fetchFilteredData = inTransitFilteredData({}).type;
export const postOpenCloseChallanName = postOpenCloseChallanCreator({}).type;
export const enableDisableIntransitChallansName =
  enableDisableIntransitChallansCreator({}).type;
export const fetchVoucherNumberData = fetchVoucherDataCreator({}).type;
export const fetchRecieveChallans = recieveChallansCreator({}).type;

const inTransistAccessHandler = createSlice({
  name: 'IN_TRANSIST-CHALLAN_HANDLER',
  initialState: intrasistChallanInitialState,
  reducers: {
    setInTransistAccessType: (prevState, {payload})  => {
      return {
        ...prevState,
        inTransistAccessType: payload,
        selectedChallans: [],
      };
    },
    setInTransistAccessInit: prevState => {
      return {
        ...prevState,
        intransitChallanloading: true,
      };
    },
    setInTransistAccesssSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        challanData: payload,
        selectedChallans: [],
        intransitChallanloading: false,
      };
    },
    setInTransistAccessError: (prevState, {payload}) => {
      return {
        ...prevState,
        intransitChallanloading: false,
        intransitChallanError: payload,
      };
    },
    setAutoSuggestionsData: prevState => {
      return {
        ...prevState,
        autoSuggestionData: [],
      };
    },
    setAutoSuggestionDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        autoSuggestionData: payload,
      };
    },
    setInTransitFilteredData: prevState => {
      return {
        ...prevState,
        filteredData: [],
      };
    },
    setFilteredDataSuccess: (prevState, {payload}) => {
      return {
        ...prevState,
        filteredData: payload,
      };
    },
    setSelectedIntransitChallans: (prevState, {payload}) => {
      return {
        ...prevState,
        selectedChallans: payload,
      };
    },
    setVoucherNumberData: (prevState, {payload}) => {
      return {
        ...prevState,
        voucherNumber: payload,
        voucherDataLoading: false,
      };
    },
    setRecievedChallansData: (prevState, {payload}) => {
      return {
        ...prevState,
        recievedChallans: payload,
        intransitChallanloading: false,
      };
    },
    setSearchTypeAndSearchValue: (prevState, {payload}) => {
      return {
        ...prevState,
        searchType: payload.searchType,
        searchValue: payload.searchValue,
      };
    },
  },
});

export const inTransistAccessActions = inTransistAccessHandler.actions;
export const inTransistAccessReducer = inTransistAccessHandler.reducer;


//missing expectedUtilzationDateActions
