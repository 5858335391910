import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { Gridstyle } from "@app/components/widgets/grid/grid";
import { useCallback } from "react";

export const ErrorGrid = ({ ErrorData }: { ErrorData: any }) => {
  const columns = [
    {
      field: "staffCode",
      headerName: "Staff Code",
      width: 220,
      editable: false,
    },
    {
      field: "month",
      headerName: "Month",
      width: 220,
      editable: false,
    },

    {
      field: "year",
      headerName: "Year",
      width: 220,
      editable: false,
    },
    {
        field: "errorReason",
        headerName: "Error Reason",
        width: 260,
        editable: false,
        renderCell: (params: GridRenderCellParams<any>) => {
          return (
            <p style={{ maxWidth: "100%", wordBreak: "break-word" }}>
              {params.row.errorReason}
            </p>
          );
        },
      },
  ];
  const getRowIdstaffCode = useCallback(function(row:any){
    return row?.staffCode
  },[])
  return (
    <Box sx={Gridstyle.gridcss}>
      <DataGrid
        rows={ErrorData}
        getRowId={getRowIdstaffCode}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  );
};
