import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { routeSelector } from '@app/router/redux';
import { makeStyles } from '@mui/styles';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { approveExpenseStateSelector } from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/selectors';
import { approveExpenseStateActions } from '@app/screens/module-specific-utilities/pages/approve-expenses/redux';
import { useCallback } from 'react';
import { SEARCH_OBJECT } from '@app/screens/module-specific-utilities/module-specific-utilities.types';
import { fetchExpenseApprovalsDataCreator } from '@app/screens/module-specific-utilities/pages/approve-expenses/redux/slice';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  flexSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
  },
  btn: {
    border: 'none',
    '&:focus': {
      border: 'none',
    },
  },
});

export const ApproveExpensesNavBar = () => {
  const dispatch = useDispatch();

  const navbarComponentHeading = useSelector(
    routeSelector.getNavbarComponentHeading()
  );
  const screenDetails = useSelector(
    approveExpenseStateSelector.getApproveExpenseScreen()
  );
  const classes = useStyles();
  const filteredData = useSelector(
    approveExpenseStateSelector?.getFilterData(),
  );

  const selectedManagerData: SEARCH_OBJECT = useSelector(
    approveExpenseStateSelector?.getSelectedManagerData(),
  );
  const selectedStaffData: SEARCH_OBJECT = useSelector(
    approveExpenseStateSelector?.getSelectedStaffData(),
  );
  const handleBack = useCallback(() =>{
    dispatch(
      approveExpenseStateActions.setApproveExpenseScreen({
        screen: 1,
        backBtn: false,
      })
    );
    dispatch(approveExpenseStateActions.setConfirmExpenseApprovalsReset());
    dispatch(approveExpenseStateActions?.setExpenseApprovalsData([]));
    dispatch(approveExpenseStateActions?.setSelectedExpenseApprovalsData([]));
    dispatch(
      approveExpenseStateActions?.setIsExpenseAprrovalsAllSelected(false),
    );    
    dispatch(approveExpenseStateActions.setDailyEditedRowColorIndex([]));
    dispatch(approveExpenseStateActions.setMiscEditedRowColorIndex([]));
    dispatch(approveExpenseStateActions?.setSelectedExpenseApprovalsUser({}));
    dispatch(approveExpenseStateActions.setRejectExpenseApprovalsReset());
    dispatch(approveExpenseStateActions.setRejectExpenseModalCheckbox(false));

    const dropdownValues = {
      divisionId: filteredData?.divisionId,
      month: filteredData?.month,
      year: filteredData?.year,
      statusId: filteredData?.statusId,
      stateId: filteredData?.stateId === undefined ? 0 : filteredData?.stateId,
      managerStaffPositionId:
        selectedManagerData !== null ? selectedManagerData?.staffPositionId : 0,
      managerUserId:
        selectedManagerData !== null ? selectedManagerData?.userId : 0,
      staffPositionId:
        selectedStaffData !== null ? selectedStaffData?.staffPositionId : 0,
      staffUserId: selectedStaffData !== null ? selectedStaffData?.userId : 0,
    };

    dispatch(fetchExpenseApprovalsDataCreator(dropdownValues));
    dispatch(
      approveExpenseStateActions.setIsExpenseAprrovalsAllSelected(false),
    );
  },[filteredData,selectedStaffData,selectedManagerData]);

  return (
    <div className={classes.container}>
      <div className={classes.flexSpaceBetween}>
        {screenDetails?.backBtn && (
          <Button variant='text' className={classes.btn} onClick={handleBack}>
            <ArrowBackIosNewOutlinedIcon />
          </Button>
        )}
        <Typography
          variant='h6'
          noWrap
          component='div'
          color={'#322b7c'}
          fontWeight={'800'}
        >
          {navbarComponentHeading}
        </Typography>
      </div>
    </div>
  );
};
