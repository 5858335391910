import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import {LeftSideBar} from '../left-sidebar';
import {RightSidebar} from '../right-sidebar';
import {NavBar} from '../navbar';
import {useSelector, shallowEqual} from 'react-redux';
import {routeSelector} from '@app/router/redux';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {authSelector} from '@app/screens/generic/Login/redux';
import { ReactElement } from 'react';
const drawerWidth = 240;

const Main: any = styled('main', {
  shouldForwardProp: prop => prop !== 'open',
})((props: any) => ({
  flexGrow: 1,
  padding: '5px 0px 0px 0px',
  width: 'calc(100% - 525px)',
  borderTopRightRadius: 25,
  backgroundColor: '#fff',
  borderTopLeftRadius: 25,
  transition: props.theme.transitions.create('margin', {
    easing: props.theme.transitions.easing.sharp,
    duration: props.theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(props.open && {
    transition: props.theme.transitions.create('margin', {
      easing: props.theme.transitions.easing.easeOut,
      duration: props.theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: 150,
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const Layout = ({children}: {children: ReactElement}) => {
  const sideBarOpen = useSelector(routeSelector.getSideBarOpen(), shallowEqual);
  const isAuthorized = useSelector(
    authSelector.getIsAuthorized(),
    shallowEqual,
  );
  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <NavBar authorized={isAuthorized} />
      <LeftSideBar authorized={isAuthorized} />
      <Main open={sideBarOpen}>
        <DrawerHeader />
        <ToastContainer />
        <Box>{children}</Box>
      </Main>
      {isAuthorized && <RightSidebar />}
    </Box>
  );
};
