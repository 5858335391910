import {
  Daily_Count,
  Daily_Expense,
  Misc_Count,
  Misc_Expense,
} from '@app/screens/module-specific-utilities/module-specific-utilities.types';

const getFlatRows = (rows: any, result = []) => {
  let flatRows: any = result;
  rows.map((item: any) => {
    flatRows.push(item);
    if (item?.subordinates) {
      return getFlatRows(item.subordinates, flatRows);
    }
    return item;
  });

  return flatRows;
};

const excelHeaders = [
  { label: 'Month', key: 'expenseMonth' },
  { label: 'Rejected Reason', key: 'adminComment' },
  { label: 'Staff Code', key: 'staffCode' },
  { label: 'Staff Name', key: 'staffName' },
  { label: 'Designation', key: 'designationName' },
  { label: 'HQ', key: 'hqName' },
  { label: 'Passed TA Amt Admin', key: 'adminApprovedTaAmount' },
  { label: 'Passed DA Amt Admin', key: 'adminApprovedDaAmount' },
  { label: 'Passed Misc Amt Admin', key: 'adminApprovedMiscAmount' },
  { label: 'Claimed DA Amt', key: 'dacustomTotalAmount' },
  { label: 'Claimed TA Amt', key: 'tacustomTotalAmount' },
  { label: 'Claimed Misc Amt', key: 'miscCustomTotalAmount' },
  { label: 'Total TA passed Manager', key: 'managerApprovedTaAmount' },
  { label: 'Total DA passed Manager', key: 'managerApprovedDaAmount' },
  { label: 'Total Misc passed Manager', key: 'managerApprovedMiscAmount' },
  { label: 'Total Amt Claimed', key: 'totalClaimedAmount' },
  { label: 'Total Amt Passed Manager', key: 'managerApprovedTotalAmount' },
  { label: 'Total Amt passed Admin', key: 'adminApprovedTotalAmount' },
];
const generateLastTenYears = (): number[] => {
  const currentYear = new Date().getFullYear();
  let min = currentYear - 9;
  let years: number[] = [];
  for (let year = currentYear; year >= min; year--) {
    years.push(year);
  }
  return years;
};



const statusOptions = [
  {
    key: 4,
    value: 'All',
  },
  {
    key: 1,
    value: 'Approved',
  },
  {
    key: 2,
    value: 'Rejected',
  },
  {
    key: 3,
    value: 'Unapproved',
  },
];

const updatedDailyData = (dailyData: Daily_Expense[]) => {
  const dailyDataCount = dailyData?.reduce(function (
    acc: Daily_Count,
    curr: Daily_Expense
  ): Daily_Count {
    let temp: Daily_Count = acc;
    if (curr && temp && temp[curr?.hqTypeAdmin]) {
      temp[curr?.hqTypeAdmin] = Number(temp[curr?.hqTypeAdmin]) + 1;
    } else {
      temp[curr?.hqTypeAdmin] = 1;
    }
    if (curr && temp && temp['adminPassedTA']) {
      temp['adminPassedTA'] =
        Number(temp['adminPassedTA']) + Number(curr?.adminPassedTA);
    } else {
      temp['adminPassedTA'] = Number(curr?.adminPassedTA);
    }
    if (curr && temp && temp['adminPassedDA']) {
      temp['adminPassedDA'] =
        Number(temp['adminPassedDA']) + Number(curr?.adminPassedDA);
    } else {
      temp['adminPassedDA'] = Number(curr?.adminPassedDA);
    }
    if (curr && temp && temp['adminPassedDistance']) {
      temp['adminPassedDistance'] =
        Number(temp['adminPassedDistance']) + Number(curr?.adminPassedDistance);
    } else {
      temp['adminPassedDistance'] = Number(curr?.adminPassedDistance);
    }
    return temp;
  },
  {});
  return dailyDataCount;
};

const updatedMiscData = (miscData: Misc_Expense[]) => {
  const miscDataCount = miscData?.reduce(function (
    acc: Misc_Count,
    curr: Misc_Expense
  ): Misc_Count {
    let temp: Misc_Count = acc;
    if (curr && temp && temp['amountAdminPassed']) {
      temp['amountAdminPassed'] =
        Number(temp['amountAdminPassed']) + Number(curr?.amountAdminPassed);
    } else {
      temp['amountAdminPassed'] = Number(curr?.amountAdminPassed);
    }
    return temp;
  },
  {});
  return miscDataCount;
};

export {
  getFlatRows,
  excelHeaders,
  generateLastTenYears,
  statusOptions,
  updatedDailyData,
  updatedMiscData,
};
