import { createAction, createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
    metricPropertiesList: any;
}

const initialState: initialStateProps = {
    metricPropertiesList: null,
}


export const metricPropertiesPageLists = {
    metricProperties: "metricProperties",
  };

export const metricPropertiesListCreator = createAction<any>("METRIC_PROPERTIES_LIST");

export const metricPropertiesListTypeName = metricPropertiesListCreator(null).type;



const metricPropertiesSlice = createSlice({
    name: "METRIC_PROPERTIES",
    initialState: initialState,
    reducers: {
        fetchMetricPropertiesList: (state, action) => {
            return {
                ...state,
                metricPropertiesList : action.payload,
            };
        },
        fetchMetricPropertiesNewList: (state, action) => {
            return {
              ...state,
              metricPropertiesList: [
                ...state.metricPropertiesList,
                ...action.payload,
              ],
            };
          },
    }
})

export const metricPropertiesAction = metricPropertiesSlice.actions;
export const metricPropertiesReducer = metricPropertiesSlice.reducer;












