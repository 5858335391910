import { makeStyles } from "@mui/styles"
import {Edit} from '@mui/icons-material';
import {
  Typography,
  styled,
  Paper,
  lighten,
  darken,
} from '@mui/material';
export const useStyles = makeStyles({
    navbarComponentHeading: {
        width: 200,
        height: 24.7,
        fontFamily: 'Poppins',
        fontSize: 18,
        fontWeight: '600',
        fontStyle: 'normal',
        letterSpacing: 0,
        textAlign: 'left',
        color: '#1c1939',
      },
      navbarComponentHeadingEmpty: {
        width: 200,
        height: 75,
        fontFamily: 'Poppins',
        fontSize: 18,
        fontWeight: '600',
        fontStyle: 'normal',
        letterSpacing: 0,
        textAlign: 'left',
        color: '#1c1939',
      },
      justifyContentcenterflex:{
        display: 'flex', justifyContent: 'center',width:'100%'
      },
      justifyContentbtflex:{
        display: 'flex', justifyContent: 'space-between',width:'100%'
      },
      stackstyle:{
                  alignItems:"center",
                  paddingLeft:1,
                  spacing:2
      },
      errorGrid:{
        height: "300px", width: "100%", margin: "2%" 
      },
      datepickrender:{
        width: 260, marginLeft: 2 
      },
      mandatoryField:{
        flex: 1,
        fontFamily: "Poppins",
        height: 18,
        fontSize: 12.7,
        fontStyle: "normal",
        fontWeight: "bold",
        color: "red",
        textAlign: "left",
      },
      width60: { width: "65%", minWidth: 310, marginTop: 1 },
      width30: { width: "30%" },
      flexSpaceBetween: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        width: "100%",
      },
      labelSizing: {
        flexDirection: "row",
        display: "flex",
    
        fontSize: 12,
        paddingLeft: 5,
        fontFamily: "Poppins",
      },
      labelcss: {
        fontSize: 12,
        paddingRight: "2%",
      },
      formControlItem: {
        marginTop: 1,
        minWidth: 310,
      },
      toolbar: {
        backgroundColor: "white",
        color: "#1e1c3b",
        padding: "5px",
        borderRadius: "5px",
        marginTop: -10,
      },
      nextrow: {
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 5px",
      },
      button: {
        borderRadius: '5px',
        fontStyle: 'normal',
        textAlign: 'center',
        fontWeight: 'bold',
        textTransform: 'capitalize',
        fontSize: 12.7,
        minWidth:"0px",
      },
      Gobutton: {
        backgroundColor: "#322b7c",
        borderRadius: "5px",
        width: "auto",
        color: "#fff",
        fontFamily: "Poppins",
        fontStyle: "normal",
        textAlign: "center",
        fontWeight: "normal",
        fontSize: "12px",
        "&:hover": {
          background: "#322b7c",
        },
      },
      width100: {
        width: "100%",
      },
      gridStyle: {
        paddingLeft: -40,
      },
      orStyle: {
        marginRight: -60,
        fontFamily: "Poppins",
        fontSize: 12.7,
        textAlign: "left",
        color: "#494761",
        opacity: 0.56,
      },
      or2Style: {
        marginRight: -60,
        marginTop: 15,
        fontFamily: "Poppins",
        fontSize: 12.7,
        textAlign: "left",
        color: "#494761",
        opacity: 0.56,
      },
    
      margintopcss: {
        marginTop: "33%",
        fontSize: "12px",
        marginLeft: "10%",
      },
      datepick: {
        "& .MuiInputBase-input-MuiOutlinedInput-input": {
          padding: "10px",
          fontSize: "12px",
        },
        "& .MuiInputBase-root-MuiOutlinedInput-root": {
          background: "#fff",
          zIndex: 1,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          fontSize: "12px",
          fontStyle: "normal",
          borderColor: "#322b7c",
          borderRadius: "4px",
        },
        "& .MuiPaper-root-MuiPickersPopper-paper":{
          marginLeft:"25px !important"
        }
      },
      formlabel: {
        fontSize: "14px",
       
        "&.MuiFormLabel-root.Mui-focused": {
          color: "#1e1c3b",
        },
        "&.MuiTypography-root": {
          fontSize: "12px",
        },
        "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
          fontSize: "12px",
        },
      },
      marginleft2_5:{
        marginLeft: "2.5%",
        fontSize:'12px'
      },
      marginleft3:{
        marginLeft: '3%'
      },
      paddingleft40:{
        paddingLeft:'40px !important'
      },
      paddingleft30:{
        paddingLeft:'30px !important'
      },
      marTop:{
        marginTop: '-17 !important'
      },
      sizeofdropdown:{
        marginTop: '1px', minWidth: '310px'
      },
      lefttopbottom:{
        paddingLeft: '40px !important', marginTop: '-16px', marginBottom: '16px'
      },
      Labelforbill:{
        paddingLeft: '40px !important', marginTop: '-35px'
      },
      minmax:{
        marginTop: '1px', minWidth: '310px', maxWidth: '310px'
      },
      minmaxtop:{
        marginTop: '-1.5px', minWidth: '310px', maxWidth: '310px'
      },
      category:{
        paddingLeft: '40px', marginTop: '-25px'
      },
      violation:{
        paddingLeft: '40px !important',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
      },
      checkbox:{
        marginLeft: '-5px', marginRight: '-50px', bottom: '10px'
      },
      flexend:{
        justifyContent: 'flex-end', marginTop: '-40px',display: 'flex',
        flexDirection: 'row',
      },
      rigth:{
        right: 120
      },
      download: {
        padding: "2%",
        textAlign: "right",
        textDecoration: "underline",
        color: "#1c1939",
        cursor: "pointer",
      },
      errorcss: {
        margin: "5px",
        color: "red",
      },
      expensego:
      {
        backgroundColor: "#322b7c",
    borderRadius: "5px",
    //width: "auto",
    color: "#fff",
    fontFamily: "Poppins",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: "normal",
    fontSize: 12.7,
    marginTop: "25px",
    marginLeft: "5px",
    "&:hover": {
      background: "#322b7c",
    },
      },
      toolbarexpense: {
        backgroundColor: "#f4f4f4",
        color: "#1e1c3b",
        padding: "13px",
        borderRadius: "5px",
        margin: "5px",
        display: "flex",
        justifyContent: "space-between",
        marginTop: -15,
      },
      toLink: {
        textDecoration: 'underline',
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        cursor: 'pointer',
        height: 'auto',
        fontSize: '10px',
      },
      toTitle: {
        fontWeight: 'inherit',
        coloe: '#000',
        fontSize: 'inherit',
      },
      flexSpaceBetweenmargin: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        width: '100%',
        marginTop: '10px',
      },
      tableCell: {borderBottom: 'none', padding: 0},
      tableCellEmpty: {borderBottom: 'none'},
      approveExpenseTab:{
        borderBottom: 1, borderColor: "divider"
      },
      dailogapproveExpenseTab:{
        display: "flex", justifyContent: "flex-end", mb: 2
      },
      balckClolor:{
        color:'black'
      },
      mt:{
        display: 'flex', justifyContent: 'center', mt: 10 
      },
      minwidth:{
        minWidth: 650
      },
  
    label: {
      fontSize: 12,
      color: "#322b7c",
    },
    selectPlaceholder: {
      "& input::placeholder": {
        fontSize: "12px",
      },
    },
   
})
export const BillLabel = styled(Typography)({
    fontSize: 12,
    lineHeight: '20px',
    color: '#322b7c',
  
  });
export const EditIcon = styled(Edit)(({theme}) => ({
    marginLeft: -5,
    fontSize: 'small',
  }));
  
  export const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    boxShadow: "none",
    color: theme.palette.text.secondary,
    justifyContent: "space-between",
    display: "flex",
  }));
  const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
  export const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 255,
      },
    },
  };

  const ITEM_HEIGHTs = 100;
export const MenuProp = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHTs * 4.5 + ITEM_PADDING_TOP,
      width: 'auto', 
    },
  },
};  

export const  renderInputStaffCodes={
  width: 190.7,
  top: 17,
  background: "#ffffff",
  'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
  {
    WebkitAppearance: 'none !important',
    margin: 0,
  },
  'input[type=number]': {
    MozAppearance: 'textfield !important',
  },
}

export const styles = {
  textFieldContainerStyles: {
    display: 'flex',
    justifyContent: 'flex-end',
    p: 0,
  },
  labelStyles: {
    mr: 1,
    fontSize: 10,
    fontWeight: 600,
    minWidth: '45%',
    textAlign: 'right',
    display: 'flex',
    alignItems: 'center',
  },
  textField: {
    height: '25px',
  },
  fieldSetStyles: {
    display: 'flex',
    flexDirection: 'column',
    ml: 3,
  },
  fieldsetContainerStyles: {
    display: 'flex',
    flexDirection: 'row',
    p: 0,
  },
  textFieldstyles: {
    height: 10,
  },
  textFieldReadOnly: {
    input: {color: 'black', padding: "8px !important"},
    backgroundColor: '#FFDAB9',
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "8px !important",
      fontSize: "12px",
      color: "#1c1939",
      fontStyle: "normal",
    },
  },
  textFieldReadOnlyBold: {
    input: {color: 'black', fontWeight: 600, padding: "8px !important"},
    backgroundColor: '#FFDAB9',
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "8px !important",
      fontSize: "12px",
      color: "#1c1939",
      fontStyle: "normal",
    },
  },
  textFieldReadOnlyYellow: {
    input: {color: 'black', padding: "8px !important"},
    backgroundColor: '#F8F4A6',
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "8px !important",
      fontSize: "12px",
      color: "#1c1939",
      fontStyle: "normal",
    },
  },
  textFieldReadOnlyBoldYellow: {
    input: {color: 'black', fontWeight: 600, padding: "8px !important"},
    backgroundColor: '#F8F4A6',
    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
      padding: "9px !important",
      fontSize: "12px",
      color: "#1c1939",
      fontStyle: "normal",
    },
  },
  btn: {
    minWidth: 90,
  },
};
export const useStyle = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  bottomNavigationListItemIcon: {minWidth: 0, marginRight: 10},
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  uploadbutton: () => {
    return {
      backgroundColor: '#dde8ec',
      borderRadius: '5px',
      border: '1px solid #322b7c',
      width: '150px',
      color: '#322b7c',
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      textAlign: 'center',
      fontWeight: 'normal',
      fontSize: 12.7,
      marginRight: '20px',
      textTransform: 'capitalize',
      '&:hover': {
        background: '#dde8ec',
      },
    };
  },
});
const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.5);

  export const referColor={
    margin:'5px 15px',fontSize:'8px'
  }
   export const nameStyles =(color: any)=>{
return {
  border: 'solid 2px,#d4f7d4', padding: '5px 10px',margin:'5px 5px',backgroundColor:color
}
  }

export const dailyDataGrid  = (height: number,errorHieght:number)=>{
  return{ height: height,
               display:'flex',
         marginBottom: '10px',
         width: '100%',
        'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
          {
            WebkitAppearance: 'none',
            margin: 0,
          },
        'input[type=number]': {
          MozAppearance: 'textfield',
          justifyContent: 'center !important'
        },
        '& .number':{
          justifyContent: 'center !important'
        },
        ' & .MuiDataGrid-cell': {
          lineHeight: 'unset !important',
          whiteSpace: 'normal !important',
          wordBreak: 'break-all !important',
          fontSize:'12px !important'
        },
        '& .MuiDataGrid-columnHeaderTitle': {
          lineHeight: 'normal !important',
          whiteSpace: 'normal !important',
          wordBreak: 'break-all !important',
          color: '#747379',
          textTransform: 'uppercase',
          letterSpacing: 0,
          textAlign: 'left',
          fontSize: 10,
          fontWeight: '600',
          fontStyle: 'normal',
          
        },
        '& .super-app.disable': {
          backgroundColor: '#d8d2d3',
          color: '#1a3e72',
          fontWeight: '600',
          cursor: 'not-allowed! important',
          pointerEvents:'none'
        },
        '& .MuiDataGrid-cell--editing':{
          backgroundColor:"#fff !important"
        },
        '& .MuiTableCell-root':{
          borderBottom: '0px solid rgba(224, 224, 224, 1) !important',
        },
        '& .MuiDataGrid-columnHeaders':{
          borderBottom: "2px solid rgb(179 173 173)"
        },
        '& .MuiDataGrid-row': {
          wordBreak: 'break-all !important',
          fontSize:'12px !important'
        },
        '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
          borderRight: '1px solid rgba(224, 224, 224, 1)',
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
          borderBottom: '1px solid rgba(224, 224, 224, 1)'
        
        },
        
        '& .super-app-theme--HOLIDAY': {
          backgroundColor: '#d4f7d4',
          '&:hover': {
            backgroundColor: '#d4f7d4',
          },
        }, 
        '& .super-app-theme--highlight,.super-app-theme--WORK': {
          backgroundColor: '#f6b9b9 !important',
          '&:hover': {
            backgroundColor: '#f6b9b9 !important',
          },
        }, 
  '& .MuiDataGrid-cell--editable': {
    bgcolor: (theme: {palette: {mode: string}}) =>
      theme.palette.mode === 'dark'
        ? '#376331'
        : 'rgb(245 198 125)',
  },
  '& .Mui-error': {
    bgcolor: (theme: {palette: {mode: string}}) =>
      `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: '#f44336',
    width: '100%',
    height:errorHieght
  },
}
}
export const radiobutton={
  ' .MuiFormControlLabel-root':{
    fontSize:'12px !important'
  }
}
export const flexSpaceBetween= {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'row',
}
export const fieldCss={
  fieldactivity:{
    textAlign: "center", marginBottom: "15px" 
  },
  fieldActivities:{
    height: 300, width: "100%", marginBottom: "50px"
  }  ,
  fieldActivityItems:{
    height: 300, width: "100%", margin: "50px 0px"
  },
  ' & .MuiDataGrid-cell': {
    lineHeight: 'unset !important',
    whiteSpace: 'normal !important',
    wordBreak: 'break-all !important',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    lineHeight: 'normal !important',
    whiteSpace: 'normal !important',
    wordBreak: 'break-all !important',
  },
  '& .MuiDataGrid-row': {
    wordBreak: 'break-all !important',
  },
}
export const LegendColor=[
  {
    name:'DISABLED CELL',
    color:'#d8d2d3'
  },
  {
    name:'EDITABLE ROW',
    color:'rgb(245 198 125)'
  },]