import {API_PATH} from '@app/common/constants';
import {NetworkService} from '@app/services';
import {takeLatest, put, call} from 'redux-saga/effects';
import {
  inventoryConfiguratorDivisionName,
  inventoryManagementConfiguratorStateActions,
  inventoryConfiguratorCustomerTypeName,
  inventoryConfiguratorHeirarchyName,
  inventoryConfiguratorDoctorSpecialityName,
  inventoryConfiguratorDoctorQualifcationName,
  inventoryConfiguratorSpecCategoryName,
  inventoryConfiguratorRolesName,
  inventoryConfiguratorItemCategoryName,
  inventoryConfiguratorClassCodeName,
  inventoryConfiguratorItemCodeName,
  inventoryConfiguratorSetValueName,
} from './slice';

export function* fetchInventoryConfiguratorDivisionWatcher() {
  yield takeLatest(
    inventoryConfiguratorDivisionName,
    fetchInventoryConfiguratorDivisionWorker,
  );
}

export function* fetchInventoryConfiguratorDivisionWorker(): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryDivision}`;

    const divisionDropdown = yield call(NetworkService.get, url, {}, {});

    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorDivisionSuccess(
        divisionDropdown?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchInventoryConfiguratorCustomerTypeWatcher() {
  yield takeLatest(
    inventoryConfiguratorCustomerTypeName,
    fetchInventoryConfiguratorCustomerTypeWorker,
  );
}

export function* fetchInventoryConfiguratorCustomerTypeWorker(): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryCustomerType}`;

    const divisionDropdown = yield call(NetworkService.get, url, {}, {});

    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorCustomerTypeSuccess(
        divisionDropdown?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchInventoryConfiguratorRegionHeirarcyWatcher() {
  yield takeLatest(
    inventoryConfiguratorHeirarchyName,
    fetchInventoryConfiguratorRegionHeirarcyWorker,
  );
}

export function* fetchInventoryConfiguratorRegionHeirarcyWorker(): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryRegionHeirarchy}`;

    const divisionDropdown = yield call(NetworkService.get, url, {}, {});

    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorRegionHeirarchySuccess(
        divisionDropdown?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchInventoryConfiguratorDoctorSpecialityWatcher() {
  yield takeLatest(
    inventoryConfiguratorDoctorSpecialityName,
    fetchInventoryConfiguratorDoctorSpecialityWorker,
  );
}

export function* fetchInventoryConfiguratorDoctorSpecialityWorker(
  action: any,
): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryDoctorSpeciality}`;
    const divisionDropdown = yield call(NetworkService.get, url, {}, {});
    console.log(divisionDropdown, 'division dropdown');
    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorDoctorSpecialitySuccess(
        divisionDropdown?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchInventoryConfiguratorDoctorQualificationWatcher() {
  yield takeLatest(
    inventoryConfiguratorDoctorQualifcationName,
    fetchInventoryConfiguratorDoctorQualificationWorker,
  );
}

export function* fetchInventoryConfiguratorDoctorQualificationWorker(
  action: any,
): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryDoctorQualification}`;
    const divisionDropdown = yield call(
      NetworkService.post,
      url,
      [...action.payload],
      {},
    );
    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorDoctorQualificationSuccess(
        divisionDropdown?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchInventoryConfiguratorSpecCategoryWatcher() {
  yield takeLatest(
    inventoryConfiguratorSpecCategoryName,
    fetchInventoryConfiguratorSpecCategoryWorker,
  );
}

export function* fetchInventoryConfiguratorSpecCategoryWorker(
  action: any,
): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventorySpecCategory}`;

    const divisionDropdown = yield call(
      NetworkService.post,
      url,
      [...action.payload],
      {},
    );

    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorSpecCategorySuccess(
        divisionDropdown?.data,
      ),
    );
  } catch (error) {}
}
export function* fetchInventoryConfiguratorRolesWatcher() {
  yield takeLatest(
    inventoryConfiguratorRolesName,
    fetchInventoryConfiguratorRolesWorker,
  );
}

export function* fetchInventoryConfiguratorRolesWorker(): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryRoles}`;

    const rolesDropDown = yield call(NetworkService.get, url, {}, {});

    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorRolesSuccess(
        rolesDropDown?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchInventoryConfiguratorItemCategoryWatcher() {
  yield takeLatest(
    inventoryConfiguratorItemCategoryName,
    fetchInventoryConfiguratorItemCategoryWorker,
  );
}
export function* fetchInventoryConfiguratorItemCategoryWorker(): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryItemCategory}`;

    const itemcategoryDropdown = yield call(NetworkService.get, url, {}, {});

    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorItemCategorySuccess(
        itemcategoryDropdown?.data,
      ),
    );
  } catch (error) {}
}
export function* fetchInventoryConfiguratorClassCodeWatcher() {
  yield takeLatest(
    inventoryConfiguratorClassCodeName,
    fetchInventoryConfiguratorClassCodeWorker,
  );
}
export function* fetchInventoryConfiguratorClassCodeWorker(): any {
  try {
    const url = `${API_PATH.inventoryConfigurator.inventoryClassCode}`;

    const ClassCodeDropdown = yield call(NetworkService.get, url, {}, {});

    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorClassCodeSuccess(
        ClassCodeDropdown?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchInventoryConfiguratorItemCodeWatcher() {
  yield takeLatest(
    inventoryConfiguratorItemCodeName,
    fetchInventoryConfiguratorItemCodeWorker,
  );
}
export function* fetchInventoryConfiguratorItemCodeWorker(action: any): any {
  try {

    const url = `${API_PATH.inventoryConfigurator.inventoryItemCode}`;

    const ItemCodeDropdown = yield call(
      NetworkService.post,
      url,
      [...action.payload],
      {},
    );

    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorItemCodeSuccess(
        ItemCodeDropdown?.data,
      ),
    );
  } catch (error) {}
}

export function* fetchInventoryConfiguratorSetValueWatcher() {
  yield takeLatest(
    inventoryConfiguratorSetValueName,
    fetchInventoryConfiguratorSetValueWorker,
  );
}
export function* fetchInventoryConfiguratorSetValueWorker(action: any): any {
  try {
    const {payload} = action;
    yield put(
      inventoryManagementConfiguratorStateActions.setInventoryConfiguratorSetValueSuccess(
        payload,
      ),
    );
  } catch (error) {}
}
