import {useCallback} from 'react';
import {DataGrid, GridCellParams, GridRenderCellParams} from '@mui/x-data-grid';
import {TableCell, Box} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {billLibraryStateSelector} from './redux/selectors';
import {fetchBillDownloadCreator} from './redux/slice';
import {Gridstyle} from '@app/components/widgets/grid/grid';

export const ViewHistoryGrid = ({EmployeeData}: {EmployeeData: any}) => {
  const dispatch = useDispatch();
  const requestArray = useSelector(
    billLibraryStateSelector.geDownloadRequest(),
  );
  const getRowIdHandlerEmpId = useCallback(function (row: any) {
    return row?.employeeId;
  }, []);
  const getDownload = useCallback((e: any) => {
    const row = JSON.parse(e.currentTarget.dataset.rowdata || '') || {};
    let request = {
      employeeList: [row],
      fromDate: requestArray.fromDate,
      toDate: requestArray.toDate,
      year: requestArray.year,
      month: requestArray.month,
      expenseCategory: requestArray.expenseCategory,
      division: requestArray.division,
      designation: requestArray.designation,
      hq: requestArray.hq,
      pool: requestArray.pool,
      status: requestArray.status,
      adminStaffpositionId: requestArray.adminStaffpositionId,
    };
    dispatch(fetchBillDownloadCreator(request));
  }, []);

  const renderDownloadLink = ({row}: GridCellParams<any>) => {
    return (
      <TableCell>
        <Box
          data-testid="renderDownloadLink-button"
          data-rowData={JSON.stringify({...row})}
          onClick={getDownload}
          sx={Gridstyle.pointer}>
          {'Click Here To Download'}{' '}
        </Box>
      </TableCell>
    );
  };
  const columns = [
    {
      field: 'employeeName',
      headerName: 'Name',
      width: 330,
      editable: false,
    },
    {
      field: 'employeeId',
      headerName: 'Staff Code',
      width: 330,
      editable: false,
    },

    {
      field: 'downloadlink',
      headerName: 'Download Link',
      width: 300,
      editable: false,
      renderCell: (params: GridRenderCellParams<any>) => {
        return renderDownloadLink(params);
      },
    },
  ];

  return (
    <Box sx={Gridstyle.gridcss}>
      <DataGrid
        rows={EmployeeData}
        getRowId={getRowIdHandlerEmpId}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </Box>
  );
};
